import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { Outlet, useOutletContext } from 'react-router-dom'

import { NavigationBar, UserSideMenu } from '../../components'
import { MaintenanceModeBanner } from '../../components/maintenance-mode-banner'
import { CreateNewFolderButton } from '../../components/navigation-bar/create-new-folder-button'

import { InviteMembersButton } from '../../components/navigation-bar/invite-members-button'
import { UploadLottieButton } from '../../components/navigation-bar/upload-lottie-button'
import { config } from '../../config'
import { useCurrentSpaceStore } from '../../use-cases'
import { CreateProjectButton } from '../../components/navigation-bar/create-project'

const noop = () => {}

type Control =
  | 'None'
  | 'InviteMembers'
  | 'CreateFolder'
  | 'UploadLottie'
  | 'CreateProject'

interface PageHeaderSetters {
  setTitle: React.Dispatch<React.SetStateAction<string>>
  setControls: React.Dispatch<React.SetStateAction<Control | Control[]>>
}

interface RefetchCurrentFolder {
  refetchCurrentFolder: () => void
  setRefetchCurrentFolder: React.Dispatch<React.SetStateAction<() => void>>
}

export const useMyPageOutletContext = () =>
  useOutletContext<
    PageHeaderSetters &
      RefetchCurrentFolder & { scrollRef: React.RefObject<HTMLDivElement> }
  >()

export interface IProps {}
export const MyPage: React.FCC<IProps> = observer(() => {
  const [title, setTitle] = React.useState<string>('')

  const Controls: Record<Control, React.FCC<any>> = {
    None: () => null,
    InviteMembers: InviteMembersButton,
    CreateFolder: () => (
      <CreateNewFolderButton refetchCurrentFolder={refetchCurrentFolder} />
    ),
    UploadLottie: UploadLottieButton,
    CreateProject: CreateProjectButton,
  } as const

  const [controls, setControls] = React.useState<Control | Control[]>('None')
  const [refetchCurrentFolder, setRefetchCurrentFolder] =
    React.useState<() => void>(noop)

  const { currentUser } = useCurrentSpaceStore()

  const scrollRef = React.useRef<HTMLDivElement>(null)

  if (currentUser === undefined) return null

  return (
    <>
      {config.maintenance && <MaintenanceModeBanner />}

      <div className="flex h-screen min-w-[900px] flex-row overflow-hidden">
        <div className="border-r-solid w-[300px] flex-shrink-0 overflow-y-auto border-r-[1px] border-r-gray-200 p-6">
          <UserSideMenu />
        </div>

        <div
          className="flex h-screen w-full flex-col gap-6 overflow-auto px-10 py-6"
          ref={scrollRef}
        >
          <NavigationBar
            title={title}
            Controls={
              Array.isArray(controls)
                ? controls.map((c) => Controls[c])
                : Controls[controls]
            }
          />
          <div className="h-full">
            <Outlet
              context={{
                setTitle,
                setControls,
                refetchCurrentFolder,
                setRefetchCurrentFolder,
                scrollRef,
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
})

MyPage.displayName = 'MyPage'
