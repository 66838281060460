import {
  CornerRadiusComponent,
  EntryComponent,
  ExportPreset,
  FillsRelationsAspect,
  FpsComponent,
  HashComponent,
  NameComponent,
  OpacityComponent,
  PaintType,
  PaintTypeComponent,
  Project,
  RenderScaleTypeComponent,
  RenderSuffixComponent,
  RenderType,
  RenderTypeComponent,
  RgbaValueComponent,
  Root,
  VisibleInViewportComponent,
  getDuration,
  getSize,
  lerpClamped,
  type Point2D,
} from '@aninix-inc/model'
import { LocalStorageIo } from '@aninix/api'
import { getBase64FromImage } from '@aninix/core'
import { DotLottie } from '@dotlottie/dotlottie-js'
import pako from 'pako'
import * as R from 'ramda'
import * as React from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import { Os, config } from '../../config'
import { Renderer } from '../../modules/renderer'
import { ImagesStore } from '../../stores'
import { generateLottieFile } from '../generate-lottie-file'
import { generateAnimatedSvg } from '../generate-svg'
import { compressPngSequence } from './compress-png-sequence'
import { encodeGif } from './encode-gif'
import { encodeMp4 as encodeMp4Actual } from './encode-mp4-encoder'
import { encodeMp4 as encodeMp4Legacy } from './encode-mp4-legacy'
import { encodeWebm as encodeWebmActual } from './encode-webm-encoder'
import { encodeWebm as encodeWebmLegacy } from './encode-webm-legacy'

const userSettingsLocalStorage = new LocalStorageIo<{
  legacyExport: boolean
  gifInfiniteLoop: boolean
}>('aninix.user-settings')

// @NOTE: used to create median of remaining time
const framesRenderDurationCountLimit = 30
export const GIF_MAX_FPS = 50
const THREADS = 1 // navigator.hardwareConcurrency ?? 4

const noop = () => {}

interface IProps {
  logger: {
    log: (...args: any[]) => void
    error: (...args: any[]) => void
  }
  project: Project
  startTime?: number
  endTime?: number
  imagesStore: ImagesStore
  exportPreset: ExportPreset
  onDone: (payload: { data: Blob; fileName: string }) => void
  onError: (message: string) => void
}

interface IOutput {
  start: () => void
  cancel: () => void
  progress: number
  remainingTimeInSeconds?: number
}

/**
 * @description Allows to render video file:
 * 1. get images for each frame from canvas
 * 2. combine all images together into 1 file
 * 3. download file via default API
 * @todo add handling of errors
 * @todo improve performance and fix lagged renders
 * @example Usage is straightforward: provide required properties and then call `.start` to start rendering.
 * ```ts
 * const render = useRenderProjectToFile({ ... })
 * // later
 * render.start()
 * ```
 */
export const useRenderProjectToFile = ({
  project,
  startTime: providedStarTime,
  endTime: providedEndTime,
  exportPreset,
  imagesStore,
  onDone,
  onError,
  logger,
}: IProps): IOutput => {
  const scale = exportPreset.getComponentOrThrow(RenderScaleTypeComponent).value
  const type = exportPreset.getComponentOrThrow(RenderTypeComponent).value
  const suffix = exportPreset.getComponentOrThrow(RenderSuffixComponent).value
  const root = project.getEntityByTypeOrThrow(Root)
  const entry = project.getEntitiesByPredicate((entity) =>
    entity.hasComponent(EntryComponent)
  )[0]

  const startTime = providedStarTime ?? 0
  const endTime = providedEndTime ?? getDuration(root) - startTime
  const duration = endTime - startTime

  const renderUnsubscribe = React.useRef<typeof noop>(noop)
  const fps = React.useMemo(() => {
    if (type === RenderType.gif) {
      return Math.min(root.getComponentOrThrow(FpsComponent).value, GIF_MAX_FPS)
    }

    return root.getComponentOrThrow(FpsComponent).value
  }, [project, type])
  const totalFrames = Math.floor(duration * fps)
  const [progress, setProgress] = React.useState(0)
  const [latestFramesRenderDuration, setLatestFramesRenderDuration] =
    React.useState<number[]>([])
  const frameRenderDuration = React.useMemo(
    () => R.mean(latestFramesRenderDuration),
    [latestFramesRenderDuration]
  )
  const updateFrameRenderDuration = (value: number) => {
    setLatestFramesRenderDuration((frames) =>
      R.append(value, R.takeLast(framesRenderDurationCountLimit - 1, frames))
    )
  }

  const pixelRatio = React.useMemo(() => {
    return parseFloat(scale)
  }, [scale])

  const size: Point2D = React.useMemo(() => {
    const internalSize = getSize(entry)
    const newX = Math.floor(internalSize.x * pixelRatio)
    const newY = Math.floor(internalSize.y * pixelRatio)
    return {
      x: newX - (newX % 2),
      y: newY - (newY % 2),
    }
  }, [project, pixelRatio])

  const projectName = React.useMemo(
    () =>
      suffix !== ''
        ? `${root.getComponentOrThrow(NameComponent).value}-${suffix}`
        : root.getComponentOrThrow(NameComponent).value,
    [root, suffix]
  )

  const getCanvas = async (payload: { forceBgColor: boolean; time: number }) =>
    new Promise<OffscreenCanvas>(async (resolve) => {
      const svg = renderToStaticMarkup(
        <Renderer
          entity={entry}
          time={payload.time}
          imagesStore={imagesStore}
          forceBgColor={payload.forceBgColor}
        />
      )

      const canvas = new OffscreenCanvas(size.x, size.y)
      const context = canvas.getContext('2d')!
      const svgUrl = 'data:image/svg+xml,' + encodeURIComponent(svg)
      const svgImage = new Image(size.x, size.y)

      svgImage.onload = async () => {
        context.drawImage(svgImage, 0, 0, size.x, size.y)
        resolve(canvas)
        svgImage.remove()
      }

      svgImage.src = svgUrl
    })

  const getIterable = async function* () {
    const frameDuration = 1 / fps
    let promises: Promise<OffscreenCanvas>[] = []
    let processedImagesCount = 0

    for (let time = startTime; time <= endTime; time += frameDuration) {
      const hasNext = time + frameDuration <= endTime

      const promise = getCanvas({
        forceBgColor: isTransparent && type === RenderType.mp4,
        time,
      })
      promises.push(promise)

      if (promises.length === THREADS || !hasNext) {
        const startFrame = Date.now()
        const renderedCanvases = await Promise.all(promises)
        processedImagesCount += renderedCanvases.length
        logger.log(
          `[useRender] rendered frame ${processedImagesCount}/${totalFrames} time in ms`,
          Date.now() - startFrame
        )
        promises = []
        for (const canvas of renderedCanvases) {
          yield canvas
        }
      }
    }
  }

  const isTransparent = React.useMemo(() => {
    // @NOTE: required to properly handle cases when mp4 with transparent bg rendered incorrectly
    // More details: https://linear.app/aninix/issue/ANI-49/add-default-bg-color-to-mp4-renderers
    const hasVisibleFills = R.any((fill) => {
      const type = fill.getComponentOrThrow(PaintTypeComponent).value

      if (type === PaintType.Image) {
        return (
          fill.getComponentOrThrow(VisibleInViewportComponent).value &&
          fill.getComponentOrThrow(OpacityComponent).value === 1
        )
      }

      if (type === PaintType.Solid) {
        return (
          fill.getComponentOrThrow(VisibleInViewportComponent).value &&
          fill.getComponentOrThrow(RgbaValueComponent).value.a === 1
        )
      }

      return true
    }, entry.getAspectOrThrow(FillsRelationsAspect).getChildrenList())

    const cornerRadius = entry.getComponentOrThrow(CornerRadiusComponent).value

    return hasVisibleFills === false || cornerRadius > 0
  }, [entry])

  const startRender = () => {
    setProgress(0)
    setLatestFramesRenderDuration([])
    const imagePaints = project.getEntitiesByPredicate(
      (entity) =>
        entity.hasComponent(PaintTypeComponent) &&
        entity.getComponent(PaintTypeComponent)?.value === PaintType.Image
    )

    if (type === RenderType.lottie) {
      logger.log('[useRender] lottie export started')

      Promise.all(
        imagePaints.map(async (imagePaint) => {
          const hash = imagePaint.getComponentOrThrow(HashComponent).value
          const image = await imagesStore.getImage(hash)
          const base64 = getBase64FromImage(image!.source)

          return {
            image: base64,
            hash,
          }
        })
      )
        .then((imagesWithHashes) => {
          const imagesMap = imagesWithHashes.reduce(
            (acc, { image, hash }) => ({
              ...acc,
              [hash]: image,
            }),
            {}
          )

          return generateLottieFile({
            project,
            imagesMap,
            startTime,
            endTime,
          })
        })
        .then((lottie) => {
          const blob = new Blob([JSON.stringify(lottie)], {
            type: 'application/json',
          })
          logger.log('[useRender] lottie export finished')
          onDone({
            data: blob,
            fileName: `${projectName}.json`,
          })
        })
        .catch((err) => {
          logger.error('[useRender] lottie export', err)
          onError('Lottie export failed. Please try again.')
        })

      return
    }

    if (type === RenderType.dotLottie) {
      logger.log('[useRender] dotLottie export started')

      Promise.all(
        imagePaints.map(async (imagePaint) => {
          const hash = imagePaint.getComponentOrThrow(HashComponent).value
          const image = await imagesStore.getImage(hash)
          const base64 = getBase64FromImage(image!.source)

          return {
            image: base64,
            hash,
          }
        })
      )
        .then((imagesWithHashes) => {
          const imagesMap = imagesWithHashes.reduce(
            (acc, { image, hash }) => ({
              ...acc,
              [hash]: image,
            }),
            {}
          )

          return generateLottieFile({
            project,
            imagesMap,
            startTime,
            endTime,
          })
        })
        .then((lottie) =>
          new DotLottie()
            .setGenerator('Aninix')
            .setAuthor('Aninix')
            .setVersion('1.0')
            .addAnimation({
              id: projectName,
              data: lottie as any,
              loop: true,
              autoplay: true,
            })
            .build()
        )
        .then((dotLottie) => dotLottie.toBlob())
        .then((blob) => {
          logger.log('[useRender] dotLottie export finished')
          onDone({
            data: blob,
            fileName: `${projectName}.lottie`,
          })
        })
        .catch((err) => {
          logger.error('[useRender] dotLottie export', err)
          onError('dotLottie export failed. Please try again.')
        })

      return
    }

    if (type === RenderType.tgs) {
      logger.log('[useRender] tgs export started')

      Promise.all(
        imagePaints.map(async (imagePaint) => {
          const hash = imagePaint.getComponentOrThrow(HashComponent).value
          const image = await imagesStore.getImage(hash)
          const base64 = getBase64FromImage(image!.source)

          return {
            image: base64,
            hash,
          }
        })
      )
        .then((imagesWithHashes) => {
          const imagesMap = imagesWithHashes.reduce(
            (acc, { image, hash }) => ({
              ...acc,
              [hash]: image,
            }),
            {}
          )

          return generateLottieFile({
            project,
            imagesMap,
            startTime,
            endTime,
          })
        })
        .then((lottie) => pako.gzip(JSON.stringify(lottie)))
        .then((bytes) => {
          const blob = new Blob([bytes], {
            type: 'application/json+zip',
          })
          logger.log('[useRender] tgs export finished')
          onDone({
            data: blob,
            fileName: `${projectName}.tgs`,
          })
        })
        .catch((err) => {
          logger.error('[useRender] tgs export', err)
          onError('tgs export failed. Please try again.')
        })

      return
    }

    if (type === RenderType.svg) {
      logger.log('[useRender] svg export started')

      const svg = generateAnimatedSvg({
        project,
        images: imagesStore,
        startTime,
        endTime,
      })

      const blob = new Blob([svg], {
        type: 'application/json',
      })
      logger.log('[useRender] svg export finished')
      onDone({
        data: blob,
        fileName: `${projectName}.svg`,
      })

      return
    }

    if (type === RenderType.png) {
      renderUnsubscribe.current = compressPngSequence({
        iterable: getIterable(),
        options: {
          fps,
          framesCount: totalFrames,
          size,
        },
        onProgressUpdate: (progress, _frameRenderDuration) => {
          setProgress(progress)
          updateFrameRenderDuration(_frameRenderDuration)
        },
        onError: (message) => {
          logger.error('[useRender] sequence export', message)
          onError(message)
        },
        onDone: (video) => {
          logger.log('[useRender] sequence export finished')
          onDone({
            data: video,
            fileName: `${projectName}.zip`,
          })
        },
      })
      return
    }

    userSettingsLocalStorage
      .get()
      .then((userSettings) => ({
        shouldUseLegacy: userSettings?.legacyExport ?? false,
        gifInfiniteLoop: userSettings?.gifInfiniteLoop ?? true,
      }))
      .then(({ gifInfiniteLoop, shouldUseLegacy }) => {
        const isWindows = config.getOs() === Os.Windows

        if (type === RenderType.mp4) {
          const encodeMp4 =
            shouldUseLegacy ||
            config.videoEncoderSupported === false ||
            isWindows ||
            // @NOTE: max supported square by codec
            size.x * size.y >= 2097152
              ? encodeMp4Legacy
              : encodeMp4Actual
          logger.log('[useRender] mp4 export started')
          renderUnsubscribe.current = encodeMp4({
            iterable: getIterable(),
            options: {
              fps,
              framesCount: totalFrames,
              size,
            },
            onProgressUpdate: (progress, _frameRenderDuration) => {
              setProgress(progress)
              updateFrameRenderDuration(_frameRenderDuration)
            },
            onError: (message) => {
              logger.error('[useRender] mp4 export', message)
              onError(message)
            },
            onDone: (video) => {
              logger.log('[useRender] mp4 export finished')
              onDone({
                data: video,
                fileName: `${projectName}.${type}`,
              })
            },
          })
        } else if (type === RenderType.gif) {
          logger.log('[useRender] gif export started')

          renderUnsubscribe.current = encodeGif({
            iterable: getIterable(),
            options: {
              fps,
              framesCount: totalFrames,
              size,
              alpha: isTransparent,
              loop: gifInfiniteLoop,
            },
            onProgressUpdate: (progress, _frameRenderDuration) => {
              setProgress(progress)
              updateFrameRenderDuration(_frameRenderDuration)
            },
            onError: (message) => {
              logger.error('[useRender] gif export', message)
              onError(message)
            },
            onDone: (video) => {
              logger.log('[useRender] gif export finished')
              onDone({
                data: video,
                fileName: `${projectName}.${type}`,
              })
            },
          })
        } else if (type === RenderType.webm) {
          const encodeWebm =
            shouldUseLegacy ||
            config.videoEncoderSupported === false ||
            // @NOTE: using legacy in this case due to issues with transparency rendering using webcodecs api
            isTransparent
              ? encodeWebmLegacy
              : encodeWebmActual
          logger.log('[useRender] webm export started')
          renderUnsubscribe.current = encodeWebm({
            iterable: getIterable(),
            options: {
              fps,
              framesCount: totalFrames,
              size,
              alpha: isTransparent,
            },
            onProgressUpdate: (progress, _frameRenderDuration) => {
              setProgress(progress)
              updateFrameRenderDuration(_frameRenderDuration)
            },
            onError: (message) => {
              logger.error('[useRender] webm export', message)
              onError(message)
            },
            onDone: (video) => {
              logger.log('[useRender] webm export finished')
              onDone({
                data: video,
                fileName: `${projectName}.${type}`,
              })
            },
          })
        }
      })
  }

  const cancelRender = () => {
    renderUnsubscribe.current?.()
  }

  const totalRenderDurationInSeconds =
    type === RenderType.png
      ? totalFrames * frameRenderDuration * 0.001
      : totalFrames * frameRenderDuration * 0.001 * 2

  return {
    start: startRender,
    cancel: cancelRender,
    progress,
    remainingTimeInSeconds:
      latestFramesRenderDuration.length >= framesRenderDurationCountLimit
        ? lerpClamped(1 - progress, 0, totalRenderDurationInSeconds)
        : undefined,
  }
}
