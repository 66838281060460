import { makeAutoObservable, observable, runInAction } from 'mobx'
import * as R from 'ramda'
import * as React from 'react'

import { Point2D } from '@aninix-inc/model'
import { Image as ImageType } from '@aninix-inc/renderer'
import type { IImageProvider } from '../interfaces/providers'

type Hash = string
const MIN_SIDE = 800

export type Image = ImageType & {
  source: HTMLImageElement
}

function resizedImage(
  originalImage: HTMLImageElement,
  newSize: Point2D
): HTMLImageElement {
  const aspectRatio = originalImage.width / originalImage.height

  let newWidth = newSize.x
  let newHeight = newSize.y

  if (newWidth / newHeight > aspectRatio) {
    newWidth = newHeight * aspectRatio
  } else {
    newHeight = newWidth / aspectRatio
  }

  const canvas = document.createElement('canvas')
  canvas.width = newWidth
  canvas.height = newHeight

  const ctx = canvas.getContext('2d')!
  ctx.drawImage(originalImage, 0, 0, newWidth, newHeight)

  const resizedImage = new Image()
  resizedImage.src = canvas.toDataURL('image/png')

  canvas.remove()
  return resizedImage
}

interface IImagesStore {
  isLoading: boolean

  /**
   * @description buffer of images
   */
  images: Record<Hash, Image>

  /**
   * @description get only one image by hash
   */
  getImage: (hash: Hash) => Promise<Image | null>

  /**
   * @description get only one image by hash in sync mode
   */
  getImageSync: (hash: Hash, options?: { size?: Point2D }) => Image | null

  /**
   * @description load all images to buffer
   */
  loadImagesByHashes: (hashes: string[]) => Promise<IImagesStore>

  /**
   * @description clean up store
   */
  clean: () => IImagesStore
}

export class ImagesStore implements IImagesStore {
  isLoading: boolean = true

  images: IImagesStore['images'] = {}

  private resizedImages: Record<string, Image> = {}

  private provider: IImageProvider

  private _debug: boolean

  private getImageRequests: Record<string, Promise<Image | null>> = {}

  constructor(provider: IImageProvider, debug?: boolean) {
    makeAutoObservable(this)

    this.provider = provider
    this._debug = debug || false

    this.debug('initiated')
  }

  loadImagesByHashes: IImagesStore['loadImagesByHashes'] = async (
    hashes: string[]
  ) => {
    return new Promise<IImagesStore>((resolve, reject) => {
      runInAction(async () => {
        try {
          this.debug('hashing images...', { hashes })
          const uniqueHashes = R.uniq(hashes)
          await Promise.all(uniqueHashes.map(this.getImage))
          this.debug('images loaded')
          this.updateStatus({ isLoading: false })
          resolve(this)
        } catch (err) {
          reject(err)
        }
      })
    })
  }

  getImage: IImagesStore['getImage'] = async (hash) => {
    this.debug('getImageRequests', this.getImageRequests)

    if (this.getImageRequests[hash] != null) {
      this.debug('get image with hash promise in progress', hash)
      return this.getImageRequests[hash]
    }

    this.debug('get image with hash', hash)

    const newPromise = new Promise<Image | null>(async (resolve) => {
      try {
        const foundImage = this.images[hash]

        if (foundImage != null) {
          delete this.getImageRequests[hash]
          this.debug('image found', hash)
          return resolve(foundImage)
        }

        this.debug('start request', hash)
        const data = await this.provider.getImages([hash])
        this.debug('receiving image data', data)
        const promises = R.keys(data).map(async (key) => {
          const value = data[key]

          // @NOTE: convert provided image to blob and then to url.
          // So we make sure we are using in-memory image instead of base64.
          // const blob = await fetch(value.src).then((res) => res.blob())
          // const newSrc = URL.createObjectURL(blob)
          // value.src = newSrc

          this.updateImageData(key, value)
          await this.loadImage(this.images[key])
        })

        await Promise.all(promises)

        this.debug('image hash getting success', this.images[hash])
        return resolve(this.images[hash])
      } catch (err: any) {
        this.debug('image hash getting error', err)
        return resolve(null)
      } finally {
        delete this.getImageRequests[hash]
      }
    })

    this.getImageRequests[hash] = newPromise

    return newPromise
  }

  getImageSync: IImagesStore['getImageSync'] = (hash, options) => {
    const originalImage = this.images[hash]

    if (originalImage == null) {
      return null
    }

    // @NOTE: prevent image to be resized if it's already small
    // Related to https://linear.app/aninix/issue/ANI-1133/poor-images-quality
    if (originalImage.size.x <= MIN_SIDE || originalImage.size.y <= MIN_SIDE) {
      return originalImage
    }

    if (
      options?.size != null &&
      options.size.x < originalImage.size.x &&
      options.size.y < originalImage.size.y
    ) {
      const key = hash + JSON.stringify(options.size)
      const image = this.resizedImages[key]

      if (image != null) {
        return image
      }

      const isVertical = originalImage.size.x < originalImage.size.y
      const coefficient = isVertical
        ? options.size.x / originalImage.size.x
        : options.size.y / originalImage.size.y
      const newSize = {
        x: originalImage.size.x * coefficient,
        y: originalImage.size.y * coefficient,
      }
      const newImage: Image = {
        hash,
        loaded: true,
        src: originalImage.src,
        // @NOTE: prevent image to be super small.
        // Related to https://linear.app/aninix/issue/ANI-1133/poor-images-quality
        source: resizedImage(originalImage.source, newSize),
        size: newSize,
      }
      this.resizedImages[key] = newImage
      return newImage
    }

    return originalImage
  }

  clean: IImagesStore['clean'] = () => {
    runInAction(() => {
      this.debug('clean')
      this.updateStatus({ isLoading: true })
      this.images = observable.object({})
      this.getImageRequests = observable.object({})
    })
    return this
  }

  /**
   * @description update image data in the hashmap.
   * Required to move it to actions because of mobx restrictions.
   */
  private updateImageData = (key: string, data: HTMLImageElement) => {
    this.debug('update image data: hash, data', key, data)
    this.images[key] = {
      hash: key,
      source: data,
      src: data.src,
      size: { x: 0, y: 0 },
      loaded: false,
    }
  }

  /**
   * @description load image and set proper size to object
   */
  private loadImage = async (image: Image): Promise<void> => {
    this.debug('start loading image', image.hash)

    return new Promise<void>((resolve) => {
      image.source.addEventListener('load', () => {
        this.debug('image loaded', image.hash)
        this.markImageAsLoaded(image.hash, {
          size: {
            x: image.source.width,
            y: image.source.height,
          },
        })
        resolve()
      })
    })
  }

  private markImageAsLoaded = (
    hash: string,
    data: { size: { x: number; y: number } }
  ) => {
    this.debug('mark image as loaded', hash)

    if (this.images[hash] == null) {
      console.warn(`Image with hash "${hash}" does not exists`)
      return
    }

    this.images[hash].size = data.size
    this.images[hash].loaded = true
  }

  private updateStatus = (payload: { isLoading: boolean }) => {
    this.isLoading = payload.isLoading
    return this
  }

  private debug = (...args: any) => {
    if (this._debug) {
      console.log(
        '[images store]',
        `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`,
        ...args
      )
    }
  }
}

const Context = React.createContext<ImagesStore>(null as any)

export const useImagesStore = (): ImagesStore => {
  const context = React.useContext(Context)

  if (context == null) {
    throw new Error(
      'ImagesStore context not found. Use SessionProvider at the root component.'
    )
  }

  return context
}

interface IProps {
  store: ImagesStore
}
export const ImagesStoreProvider: React.FCC<IProps> = ({ store, children }) => {
  return <Context.Provider value={store}>{children}</Context.Provider>
}
