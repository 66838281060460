import { Group } from '@aninix-inc/model'
import { convertNode } from '../conver-node'
import { applyCommon } from '../maps/apply-common'
import { NodeHandler } from '../types'

export const g: NodeHandler = (node, project, assets) => {
  const entity = project.createEntity(Group)
  const { opacity, style, ...passedAttributes } = node.attributes
  node.attributes = { opacity, style }
  applyCommon(entity, node, assets)
  node.children.forEach((c, i) =>
    convertNode(
      node.children.length - i,
      { ...c, attributes: { ...passedAttributes, ...c.attributes } },
      assets,
      entity,
      project
    )
  )

  return entity
}
