import {
  DurationComponent,
  Entity,
  EntityType,
  EntityTypeComponent,
  EntryComponent,
  FpsComponent,
  MetadataComponent,
  NodeType,
  NodeTypeComponent,
  OpacityComponent,
  PositionComponent,
  Project,
  RgbaValueComponent,
  Root,
  RotationComponent,
  ScaleComponent,
  SizeComponent,
  SkewComponent,
  Snapshot,
  SystemsProvider,
  TimeComponent,
  VisibleInViewportComponent,
  copyNode,
  getEntryOrThrow,
  recoverProject,
  replaceNode,
} from '@aninix-inc/model'
import { PrototypeNodeType } from '@aninix/figma'
import { Logger } from '@aninix/logger'
import { FigmaConnect } from './figma-connect'
import { Pair } from './pair'
import { PrototypeNode } from './prototype-node'
import { smartAnimateTransition } from './smart-animate.transition'

export namespace PrototypeChain {
  export type Type = {
    baseProject: (
      entryNodeId: string,
      map?: Map<string, Snapshot>
    ) => Promise<Project>

    chain: () => Promise<PrototypeNodeType[]>

    /**
     * Map chain to the project
     */
    toProject: (options?: {
      /**
       * When `true` then new project ID generated no matter what.
       * @default false
       */
      shouldGenerateNewProjectId?: boolean
    }) => Promise<Project>
  }

  export class Figma implements Type {
    constructor(
      figmaConnect: FigmaConnect,
      logger: Logger,
      payload: {
        type: 'chain'
        chain: PrototypeNodeType[]
        /**
         * Determine if instances should be converted to project or not.
         * To test `deep` property create prototype frame with animated instances inside of it.
         * Then change delay and internal properties of the instance.
         */
        deep?: boolean
      }
    )
    constructor(
      figmaConnect: FigmaConnect,
      logger: Logger,
      payload: {
        type: 'node-id'
        figmaNodeId: string
        /**
         * Determine if instances should be converted to project or not.
         * To test `deep` property create prototype frame with animated instances inside of it.
         * Then change delay and internal properties of the instance.
         */
        deep?: boolean
      }
    )
    constructor(
      private readonly figmaConnect: FigmaConnect,
      private readonly logger: Logger,
      private readonly payload:
        | {
            type: 'chain'
            chain: PrototypeNodeType[]
            deep?: boolean
          }
        | {
            type: 'node-id'
            figmaNodeId: string
            deep?: boolean
          }
    ) {}

    baseProject: Type['baseProject'] = async (entryNodeId, map) => {
      this.logger.log('[PrototypeChain.Figma] .baseProject handle', entryNodeId)

      const bgColor = await this.figmaConnect.getPageBackgroundColor()
      const snapshot =
        map?.get(entryNodeId) ??
        (await this.figmaConnect.getNodeSnapshot(entryNodeId))

      const project = recoverProject(
        {
          type: 'snapshot',
          snapshot,
        },
        {
          rewrite: {
            systemsProvider: new SystemsProvider([]),
          },
        }
      )

      const root = project.getEntityByTypeOrThrow(Root)
      root.updateComponent(RgbaValueComponent, {
        r: bgColor.color.r,
        g: bgColor.color.g,
        b: bgColor.color.b,
        a: bgColor.color.a,
      })
      root.updateComponent(VisibleInViewportComponent, bgColor.isVisible)
      root.updateComponent(FpsComponent, 60)

      this.logger.log('[PrototypeChain.Figma] .baseProject finished')

      return project
    }

    chain: Type['chain'] = async () => {
      this.logger.log('[PrototypeChain.Figma] .chain handle')

      if (this.payload.type === 'chain') {
        this.logger.log('[PrototypeChain.Figma] .chain finished')
        return this.payload.chain
      }

      const { chain, errors } = await this.figmaConnect.getPrototypeChain(
        this.payload.figmaNodeId
      )

      if (errors != null && errors.length > 0) {
        throw new Error(`${errors.map((e) => e.message).join('; ')}`)
      }

      this.logger.log('[PrototypeChain.Figma] .chain finished')

      return chain
    }

    toProject: Type['toProject'] = async (options) => {
      const chain = await this.chain()
      const deep = this.payload.deep ?? true

      this.logger.log(
        '[PrototypeChain.Figma] .toProject handle',
        chain[0].figmaNodeId
      )

      if (chain.length === 0) {
        throw new Error('Invalid chain received')
      }

      // @NOTE: map of figmaNodeId to snapshot
      const snapshotsMap = new Map<string, Snapshot>()
      for (const prototypeNode of chain) {
        const snapshot = await this.figmaConnect.getNodeSnapshot(
          prototypeNode.figmaNodeId,
          options
        )
        snapshotsMap.set(prototypeNode.figmaNodeId, snapshot)
      }

      this.logger.log('[PrototypeChain.Figma] .toProject snapshotsMap received')

      const baseProject = await this.baseProject(
        chain[0].figmaNodeId,
        snapshotsMap
      )
      const entry = getEntryOrThrow(baseProject)

      // handle map of previos prototype node entity ids to actual entities inside of the project
      const targetsMap = new Map<string, Entity>()

      for (const entity of baseProject.entities) {
        if (
          entity.getComponentOrThrow(EntityTypeComponent).value !==
          EntityType.Node
        ) {
          continue
        }

        targetsMap.set(entity.id, entity)
      }

      this.logger.log('[PrototypeChain.Figma] .toProject base targetsMap built')

      let time: number = 0
      for (let i = 1; i < chain.length; i += 1) {
        this.logger.log(
          `[PrototypeChain.Figma] .toProject processing ${i + 1}/${chain.length}`
        )
        const prevPrototypeNode = chain[i - 1]!
        const prototypeNode = chain[i]!
        const timeStart = time + prevPrototypeNode.delay
        const timeEnd = timeStart + prevPrototypeNode.duration
        const prevPrototypedFrameNodeSnapshot = snapshotsMap.get(
          prevPrototypeNode.figmaNodeId
        )!
        const prototypedFrameNodeSnapshot = snapshotsMap.get(
          prototypeNode.figmaNodeId
        )!
        const tempProjectLeft = recoverProject(
          {
            type: 'snapshot',
            snapshot: prevPrototypedFrameNodeSnapshot,
          },
          {
            rewrite: {
              systemsProvider: new SystemsProvider([]),
            },
          }
        )
        const tempProjectRight = recoverProject(
          {
            type: 'snapshot',
            snapshot: prototypedFrameNodeSnapshot,
          },
          {
            rewrite: {
              systemsProvider: new SystemsProvider([]),
            },
          }
        )
        const tempEntryLeft = getEntryOrThrow(tempProjectLeft)
        const tempEntryRight = getEntryOrThrow(tempProjectRight)

        this.logger.log(
          `[PrototypeChain.Figma] .toProject start recursive processing ${i + 1}/${chain.length}`
        )

        new Pair.Base(
          new PrototypeNode.Base(tempEntryLeft),
          new PrototypeNode.Base(tempEntryRight)
        ).forEachGrouped((pairs, parent) => {
          for (let ii = pairs.length - 1; ii >= 0; ii -= 1) {
            const pair = pairs[ii]

            try {
              // @NOTE: copy layer from right to left
              if (pair.left == null && pair.right != null) {
                const parentTarget = targetsMap.get(
                  parent?.entity.id ?? entry.id
                )

                if (parentTarget == null) {
                  throw new Error('Invalid state. Parent target not found')
                }

                const copiedEntity = copyNode(pair.right.entity, parentTarget, {
                  index: ii + 1,
                  nodeDidCopied: (source, copied) => {
                    targetsMap.set(source.id, copied)
                  },
                })

                new PrototypeNode.Base(copiedEntity).appear({
                  timeStart,
                  timeEnd,
                })
                continue
              }

              if (pair.left != null && pair.right == null) {
                const leftTarget = targetsMap.get(pair.left.entity.id)

                if (leftTarget == null) {
                  throw new Error(
                    `Invalid state. Left target not found for ID "${pair.left.entity.id}"`
                  )
                }

                new PrototypeNode.Base(leftTarget).disappear({
                  timeStart,
                  timeEnd,
                })
                continue
              }

              if (pair.left != null && pair.right != null) {
                const leftTarget = targetsMap.get(pair.left.entity.id)
                const parentTarget = targetsMap.get(
                  parent?.entity.id ?? entry.id
                )

                if (leftTarget == null) {
                  throw new Error(
                    `Invalid state. Left target not found for ID "${pair.left.entity.id}"`
                  )
                }

                if (parentTarget == null) {
                  throw new Error('Invalid state. Parent target not found')
                }

                if (!pair.canBeTransitioned()) {
                  const left = new PrototypeNode.Base(leftTarget)
                  const copiedEntity = copyNode(
                    pair.right.entity,
                    parentTarget,
                    {
                      index: ii + 1,
                      nodeDidCopied: (source, copied) => {
                        targetsMap.set(source.id, copied)
                      },
                    }
                  )
                  const copiedNode = new PrototypeNode.Base(copiedEntity)

                  const isLeftVisible =
                    pair.left.entity.getComponentOrThrow(
                      VisibleInViewportComponent
                    ).value &&
                    pair.left.entity.getComponentOrThrow(OpacityComponent)
                      .value !== 0
                  const isRightVisible =
                    pair.right.entity.getComponentOrThrow(
                      VisibleInViewportComponent
                    ).value &&
                    pair.right.entity.getComponentOrThrow(OpacityComponent)
                      .value !== 0

                  if (isLeftVisible && isRightVisible) {
                    // @NOTE: creating blend animation between 2 unrelated nodes
                    smartAnimateTransition(new Pair.Base(left, copiedNode), {
                      timeStart,
                      timeEnd,
                      curve: prevPrototypeNode.timingCurve,
                      includeComponents: [
                        PositionComponent,
                        RotationComponent,
                        ScaleComponent,
                        SizeComponent,
                        SkewComponent,
                        OpacityComponent,
                      ],
                    })
                    smartAnimateTransition(new Pair.Base(copiedNode, left), {
                      timeStart: timeEnd,
                      timeEnd: timeStart,
                      curve: prevPrototypeNode.timingCurve,
                      includeComponents: [
                        PositionComponent,
                        RotationComponent,
                        ScaleComponent,
                        SizeComponent,
                        SkewComponent,
                        OpacityComponent,
                      ],
                    })
                  }

                  copiedNode.appear({ timeStart, timeEnd })
                  left.disappear({ timeStart, timeEnd })
                  continue
                }

                // @NOTE: handle special case when entry points animated
                if (
                  pair.left.entity.hasComponent(EntryComponent) &&
                  pair.right.entity.hasComponent(EntryComponent)
                ) {
                  smartAnimateTransition(
                    new Pair.Base(
                      new PrototypeNode.Base(leftTarget),
                      pair.right
                    ),
                    {
                      timeStart,
                      timeEnd,
                      curve: prevPrototypeNode.timingCurve,
                      includeComponents: [RgbaValueComponent, OpacityComponent],
                    }
                  )
                  targetsMap.set(pair.right.entity.id, leftTarget)
                  continue
                }

                smartAnimateTransition(
                  new Pair.Base(new PrototypeNode.Base(leftTarget), pair.right),
                  {
                    timeStart,
                    timeEnd,
                    curve: prevPrototypeNode.timingCurve,
                  }
                )
                targetsMap.set(pair.right.entity.id, leftTarget)
              }
            } catch (err: any) {
              console.error(err)
            }
          }
        })

        time += prevPrototypeNode.delay + prevPrototypeNode.duration
      }

      this.logger.log('[PrototypeChain.Figma] .toProject chain processed')

      // @NOTE: set duration of the project to animated values
      // @TODO: move to the model to the `fitToAnimation(project)` function
      const keyframeTimes = baseProject
        .getEntitiesByPredicate(
          (e) =>
            e.getComponentOrThrow(EntityTypeComponent).value ===
            EntityType.Keyframe
        )
        .map((keyframe) => keyframe.getComponentOrThrow(TimeComponent).value)
      const endTime = Math.max(...keyframeTimes)
      baseProject
        .getEntityByTypeOrThrow(Root)
        .updateComponent(DurationComponent, endTime)

      this.logger.log('[PrototypeChain.Figma] .toProject duration set')

      this.logger.log('[PrototypeChain.Figma] .toProject processing instances')

      if (!deep) {
        this.logger.log('[PrototypeChain.Figma] .toProject finished')
        return baseProject
      }

      // @NOTE: replace instances with projects
      for (const entity of baseProject.entities) {
        if (entity.hasComponent(EntryComponent)) {
          continue
        }

        if (
          entity.getComponentOrThrow(EntityTypeComponent).value !==
          EntityType.Node
        ) {
          continue
        }

        if (
          entity.getComponentOrThrow(NodeTypeComponent).value !==
          NodeType.Instance
        ) {
          continue
        }

        const [frameMeta] = await this.figmaConnect.getNodesMeta([
          (entity.getComponent(MetadataComponent)?.value
            .figmaNodeId as string) ?? entity.id,
        ])

        if (frameMeta?.mainNodeId == null) {
          continue
        }

        const project = await new Figma(this.figmaConnect, this.logger, {
          type: 'node-id',
          figmaNodeId: frameMeta.id,
          deep: false,
        }).toProject()

        const entry = getEntryOrThrow(project)
        entry.removeComponent(EntryComponent)

        replaceNode(entry, entity)
      }

      this.logger.log('[PrototypeChain.Figma] .toProject instances processed')

      this.logger.log('[PrototypeChain.Figma] .toProject finished')

      return baseProject
    }
  }
}
