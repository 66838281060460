import {
  Component,
  Entity,
  TargetRelationAspect,
  components,
  getValueNumber,
  getValuePoint2d,
  getValueRgba,
  getValueSpatialPoint2d,
  round,
} from '@aninix-inc/model'
import { Point2D } from '@aninix-inc/model/legacy'
import { capitalize } from 'lodash'
import * as React from 'react'
import tinycolor from 'tinycolor2'
import { Components, Format, InfoBlock } from './mappers/types'

type Segment = [Entity, Entity]
type ValueBlockSegmentType = {
  getSupportedFormats: () => Format[]
  asBlock: (payload: { time: number; format: Format }) => InfoBlock
}

/**
 * The class maps provided segment and returns data for value blocks
 * @todo: refactor it further to isolate full entity. And move it to entities folder.
 */
export class ValueBlockSegment implements ValueBlockSegmentType {
  constructor(private readonly segment: Segment) {}

  getSupportedFormats: ValueBlockSegmentType['getSupportedFormats'] = () => {
    const [left] = this.segment

    if (
      left.getAspect(TargetRelationAspect)?.getTargetComponent() instanceof
      components.PositionComponent
    ) {
      return [Format.Pixels, Format.Percents]
    }

    return []
  }

  asBlock: ValueBlockSegmentType['asBlock'] = ({ time, format }) => {
    const [left, right] = this.segment
    const leftTime = left.getComponentOrThrow(components.TimeComponent).value
    const rightTime = right.getComponentOrThrow(components.TimeComponent).value

    const propertiesName: ReturnType<
      ValueBlockSegmentType['asBlock']
    >['propertiesName'] =
      capitalize(
        left
          .getAspectOrThrow(TargetRelationAspect)
          .getComponentTagOrThrow()
          .replace('-', ' ')
      ) + ' change'

    const properties: ReturnType<
      ValueBlockSegmentType['asBlock']
    >['properties'] = (() => {
      const aspect = this.segment[0].getAspectOrThrow(TargetRelationAspect)
      const entity = aspect.getTargetEntityOrThrow()
      const component = aspect.getTargetComponentOrThrow()
      const valueGenerator = this.valueFunctionGeneration(
        entity,
        component,
        format
      )
      return [
        {
          title: 'Start Value',
          value: valueGenerator(leftTime),
        },
        {
          title: 'Current Value',
          value: valueGenerator(time),
        },
        {
          title: 'End Value',
          value: valueGenerator(rightTime),
        },
      ]
    })()

    return {
      propertiesName,
      properties,
    }
  }

  private valueFunctionGeneration = (
    entity: Entity,
    providedComponent: Component,
    format: Format
  ): ((time: number) => React.ReactNode) => {
    const component = providedComponent as Components

    switch (true) {
      case component instanceof components.PositionComponent: {
        const size = entity.getComponentOrThrow(components.SizeComponent).value
        const startPoint = entity.getComponentOrThrow(
          components.PositionComponent
        ).value

        const getTranslateInPixels = (point: Point2D) => ({
          x: round(point.x, { fixed: 2 }),
          y: round(point.y, { fixed: 2 }),
        })
        const getTranslateInPercents = (point: Point2D) => ({
          x: round((point.x / size.x) * 100, { fixed: 0 }),
          y: round((point.y / size.y) * 100, { fixed: 0 }),
        })

        if (format === 'px') {
          return (time) => {
            const value = getValueSpatialPoint2d({
              t: time,
              segment: this.segment,
            })
            const valueInPixels = getTranslateInPixels({
              x: value.x - startPoint.x,
              y: value.y - startPoint.y,
            })
            return `${valueInPixels.x}px, ${valueInPixels.y}px`
          }
        }

        return (time) => {
          const value = getValueSpatialPoint2d({
            t: time,
            segment: this.segment,
          })
          const valueInPercents = getTranslateInPercents({
            x: value.x - startPoint.x,
            y: value.y - startPoint.y,
          })
          return `${valueInPercents.x}%, ${valueInPercents.y}%`
        }
      }

      case component instanceof components.AnchorPointComponent:
      case component instanceof components.SizeComponent:
      case component instanceof components.ShadowOffsetComponent: {
        return (time) => {
          const value = getValuePoint2d({
            t: time,
            segment: this.segment,
          })
          return `${round(value.x, {
            fixed: 0,
          })}px, ${round(value.y, {
            fixed: 0,
          })}px`
        }
      }

      case component instanceof components.SkewComponent: {
        return (time) => {
          const value = getValuePoint2d({
            t: time,
            segment: this.segment,
          })
          return `${round(value.x, {
            fixed: 0,
          })}°, ${round(value.y, {
            fixed: 0,
          })}°`
        }
      }

      case component instanceof components.ScaleComponent: {
        return (time) => {
          const value = getValuePoint2d({
            t: time,
            segment: this.segment,
          })
          return `${round(value.x * 100, {
            fixed: 0,
          })}%, ${round(value.y * 100, {
            fixed: 0,
          })}%`
        }
      }

      case component instanceof components.OpacityComponent:
      case component instanceof components.ProgressComponent:
      case component instanceof components.TrimStartComponent:
      case component instanceof components.TrimOffsetComponent:
      case component instanceof components.TrimEndComponent: {
        return (time) => {
          const value = getValueNumber({
            t: time,
            segment: this.segment,
          })
          return `${round(value * 100, {
            fixed: 0,
          })}%`
        }
      }

      case component instanceof components.BlurRadiusComponent:
      case component instanceof components.BottomLeftCornerRadiusComponent:
      case component instanceof components.BottomRightCornerRadiusComponent:
      case component instanceof components.TopLeftCornerRadiusComponent:
      case component instanceof components.TopRightCornerRadiusComponent:
      case component instanceof components.CornerRadiusComponent:
      case component instanceof components.DashComponent:
      case component instanceof components.DashOffsetComponent:
      case component instanceof components.GapComponent:
      case component instanceof components.InnerRadiusComponent:
      case component instanceof components.PointCountComponent:
      case component instanceof components.ShadowRadiusComponent:
      case component instanceof components.ShadowSpreadComponent:
      case component instanceof components.StrokeTopWeightComponent:
      case component instanceof components.StrokeBottomWeightComponent:
      case component instanceof components.StrokeRightWeightComponent:
      case component instanceof components.StrokeLeftWeightComponent:
      case component instanceof components.StrokeWeightComponent: {
        return (time) => {
          const value = getValueNumber({
            t: time,
            segment: this.segment,
          })
          return `${round(value, {
            fixed: 2,
          })}`
        }
      }

      case component instanceof components.StartAngleComponent:
      case component instanceof components.EndAngleComponent:
      case component instanceof components.RotationComponent: {
        return (time) => {
          const value = getValueNumber({
            t: time,
            segment: this.segment,
          })
          return `${round(value, {
            fixed: 0,
          })}°`
        }
      }

      case component instanceof components.ShadowColorComponent:
      case component instanceof components.RgbaValueComponent: {
        return (time) => {
          const value = getValueRgba({
            t: time,
            segment: this.segment,
          })
          const color = tinycolor(value)
          return (
            <div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <span
                  style={{
                    display: 'block',
                    width: 12,
                    height: 12,
                    borderRadius: 2,
                    background: color.toRgbString(),
                    border: '1px solid rgba(0, 0, 0, 0.4)',
                    marginRight: 4,
                  }}
                />
                #{color.toHex()}
              </div>

              {color.toRgbString()}
            </div>
          )
        }
      }

      // @TODO: should be implemented
      case component instanceof components.GradientTransformComponent:
      case component instanceof components.ImageTransformComponent: {
        throw new Error(`${component} not implemented yet`)
      }

      // @NOTE: handle not targetable components
      case component instanceof components.BlendModeComponent:
      case component instanceof components.ChildrenExpandedComponent:
      case component instanceof components.ClipContentComponent:
      case component instanceof components.DurationComponent:
      case component instanceof components.EffectTypeComponent:
      case component instanceof components.EntityTypeComponent:
      case component instanceof components.EntryComponent:
      case component instanceof components.FpsComponent:
      case component instanceof components.HashComponent:
      case component instanceof components.IndividualCornerRadiusComponent:
      case component instanceof components.IndividualStrokeWeightComponent:
      case component instanceof components.LockedComponent:
      case component instanceof components.MaskComponent:
      case component instanceof components.MatrixTransformValueComponent:
      case component instanceof components.NameComponent:
      case component instanceof components.NumberValueComponent:
      case component instanceof components.NodeColorComponent:
      case component instanceof components.NodeTypeComponent:
      case component instanceof components.PaintTypeComponent:
      case component instanceof components.PathReversedComponent:
      case component instanceof components.Point2dValueComponent:
      case component instanceof components.PropertiesExpandedComponent:
      case component instanceof components.RenderScaleTypeComponent:
      case component instanceof components.RenderSuffixComponent:
      case component instanceof components.RenderTypeComponent:
      case component instanceof components.ScaleLockedComponent:
      case component instanceof components.ScaleTypeComponent:
      case component instanceof components.ScalingFactorComponent:
      case component instanceof components.SpatialPoint2dValueComponent:
      case component instanceof components.SizeBehaviourComponent:
      case component instanceof components.SizeLockedComponent:
      case component instanceof components.SmoothCornerRadiusComponent:
      case component instanceof components.SoloComponent:
      case component instanceof components.SpringCurveComponent:
      case component instanceof components.StartTimeComponent:
      case component instanceof components.StrokeAlignComponent:
      case component instanceof components.StrokeCapStartComponent:
      case component instanceof components.StrokeCapEndComponent:
      case component instanceof components.StrokeJoinComponent:
      case component instanceof components.TimeComponent:
      case component instanceof components.TimingCurveComponent:
      case component instanceof components.VectorPathsComponent:
      case component instanceof components.VisibleInViewportComponent:
      case component instanceof components.MainNodeIdComponent:
      case component instanceof components.PresetIdComponent:
      case component instanceof components.CoverTimeComponent:
      case component instanceof components.InitialNodeIdComponent:
      case component instanceof components.MetadataComponent:
      case component instanceof components.TextAlignHorizontalComponent:
      case component instanceof components.TextAlignVerticalComponent:
      case component instanceof components.TextAutoResizeComponent:
      case component instanceof components.TextCaseComponent:
      case component instanceof components.TextDecorationComponent:
      case component instanceof components.TextListOptionsComponent:
      case component instanceof components.TextTruncationComponent:
      case component instanceof components.AutoRenameComponent:
      case component instanceof components.FontStyleComponent:
      case component instanceof components.FontNameComponent:
      case component instanceof components.FontSizeComponent:
      case component instanceof components.FontWeightComponent:
      case component instanceof components.VersionComponent:
      case component instanceof components.CharactersComponent:
      case component instanceof components.StartIndexComponent:
      case component instanceof components.EndIndexComponent:
      case component instanceof components.ParagraphIndentComponent:
      case component instanceof components.ParagraphSpacingComponent:
      case component instanceof components.HangingListComponent:
      case component instanceof components.HangingPunctuationComponent:
      case component instanceof components.MaxLinesComponent:
      case component instanceof components.IndetationComponent:
      case component instanceof components.LineHeightUnitComponent:
      case component instanceof components.LineHeightValueComponent:
      case component instanceof components.LetterSpacingUnitComponent:
      case component instanceof components.LetterSpacingValueComponent:
      case component instanceof components.LeadingTrimComponent:
      case component instanceof components.ListSpacingComponent: {
        throw new Error(`${component} is not supported in such context`)
      }

      default: {
        const never: never = component
        throw new Error(`"${never}" should never be called`)
      }
    }
  }
}
