import {
  EntityType,
  EntityTypeComponent,
  ExportPreset,
  HashComponent,
  PaintType,
  PaintTypeComponent,
  RenderScaleType,
  RenderScaleTypeComponent,
  RenderType,
  RenderTypeComponent,
} from '@aninix-inc/model'
import { usePlayerStores } from '@aninix-inc/player'

import { useRenderProjectToFile } from '@aninix/core/use-cases/render-project-to-file'

import { ExportOption as AnitypeExportOption } from 'apps/anitype-plugin/src/modules/export'

import * as React from 'react'

const exportOptions = ['.lottie', 'Video/GIF', 'SVG'] as const
const videoGifFormatOptions = ['MP4', 'WEBM', 'GIF'] as const
const videoGifScaleOptions = ['1×', '2×', '4×'] as const

type ExportOption = (typeof exportOptions)[number]
type VideoGifFormatOption = (typeof videoGifFormatOptions)[number]
export type VideoGifScaleOption = (typeof videoGifScaleOptions)[number]

export type AutostartExportOption = AnitypeExportOption | null | undefined

export interface IProps {
  projectName: string
  autostartExportOption?: AutostartExportOption
  onProgressUpdate?: (value: number) => void
  onExportFinish?: () => void
  onExportStart?: () => void
  onError?: (message: string) => void
  defaultScale?: VideoGifScaleOption
}
export const RenderHandler: React.FCC<IProps> = ({
  autostartExportOption,
  onProgressUpdate,
  onExportFinish,
  onExportStart,
  onError,
  defaultScale = '1×',
}) => {
  const playerStores = usePlayerStores()
  const images = playerStores.imagesStore
  const project = playerStores.project

  const autostartPreset: {
    exportType: ExportOption
    format: VideoGifFormatOption
  } = (() => {
    switch (autostartExportOption) {
      case 'gif':
        return {
          exportType: 'Video/GIF',
          format: 'GIF',
        }
      case 'mp4':
        return {
          exportType: 'Video/GIF',
          format: 'MP4',
        }

      case 'webm':
        return {
          exportType: 'Video/GIF',
          format: 'WEBM',
        }

      case 'lottie':
        return {
          exportType: '.lottie',
          format: 'MP4',
        }
    }

    return {
      exportType: 'Video/GIF',
      format: 'MP4',
    }
  })()

  const [isRendering, setIsRendering] = React.useState<boolean>(false)
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null)
  const [scale, setScale] = React.useState<VideoGifScaleOption>('1×')

  const exportPreset: ExportPreset = React.useMemo(() => {
    if (autostartPreset.exportType === '.lottie') {
      return project
        .createIndependentEntity(ExportPreset)
        .updateComponent(RenderTypeComponent, RenderType.lottie)
        .updateComponent(RenderScaleTypeComponent, RenderScaleType['1x'])
    }

    if (autostartPreset.exportType === 'SVG') {
      return project
        .createIndependentEntity(ExportPreset)
        .updateComponent(RenderTypeComponent, RenderType.svg)
        .updateComponent(RenderScaleTypeComponent, RenderScaleType['1x'])
    }

    const entity = project.createIndependentEntity(ExportPreset)

    switch (autostartPreset.format) {
      case 'GIF': {
        entity.updateComponent(RenderTypeComponent, RenderType.gif)
        break
      }

      case 'MP4': {
        entity.updateComponent(RenderTypeComponent, RenderType.mp4)
        break
      }

      case 'WEBM': {
        entity.updateComponent(RenderTypeComponent, RenderType.webm)
        break
      }

      default: {
        const never: never = autostartPreset.format
        throw new Error(`Shoul never reach "${never}"`)
      }
    }

    switch (defaultScale) {
      case '1×': {
        entity.updateComponent(RenderScaleTypeComponent, RenderScaleType['1x'])
        break
      }

      case '2×': {
        entity.updateComponent(RenderScaleTypeComponent, RenderScaleType['2x'])
        break
      }

      case '4×': {
        entity.updateComponent(RenderScaleTypeComponent, RenderScaleType['4x'])
        break
      }

      default: {
        const never: never = defaultScale
        throw new Error(`Shoul never reach "${never}"`)
      }
    }

    return entity
  }, [autostartPreset, defaultScale])

  const render = useRenderProjectToFile({
    project,
    exportPreset,
    //@ts-ignore
    imagesStore: images,
    onDone: ({ data, fileName }) => {
      onExportFinish?.()

      downloadUriAsBlob(data, fileName)
      // @NOTE: required when we have fast updates (e.g., rendering svg).
      // In this case the react dispatch updates in a batch and this setter is not called.
      setTimeout(() => {
        setIsRendering(false)
      }, 1)
    },
    onError: (message) => {
      setErrorMessage(message)
      onError?.(message)
      // @NOTE: required when we have fast updates (e.g., rendering svg).
      // In this case the react dispatch updates in a batch and this setter is not called.
      setTimeout(() => {
        setIsRendering(false)
      }, 1)
    },
    logger: {
      log: console.log,
      error: console.error,
    },
  })

  const startRender = React.useCallback(async () => {
    setErrorMessage(null)

    onExportStart?.()

    render.start()

    const imagePaints = project.getEntitiesByPredicate(
      (entity) =>
        entity.getComponentOrThrow(EntityTypeComponent).value ===
          EntityType.Paint &&
        entity.getComponentOrThrow(PaintTypeComponent).value === PaintType.Image
    )
    await images.loadImagesByHashes(
      imagePaints.map((paint) => paint.getComponentOrThrow(HashComponent).value)
    )

    setIsRendering(true)
  }, [render])

  // const cancelRender = React.useCallback(() => {
  //   render.cancel()
  //   setIsRendering(false)
  // }, [render])

  // const features = React.useMemo(() => {
  //   return useCases.getListOfNotSupportedLottieFeatures(project)
  // }, [project])
  // const entry = getEntryOrThrow(project)

  React.useEffect(() => {
    if (autostartExportOption != null) {
      startRender()
    }
  }, [autostartExportOption])

  React.useEffect(() => {
    if (isRendering) onProgressUpdate?.(render.progress)
  }, [render.progress, isRendering])

  return null
}

function downloadUriAsBlob(blob: Blob, name: string) {
  const uri = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.download = name
  link.href = uri
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
