import {
  ColorStopsRelationsAspect,
  ConstructorWithTag,
  Entity,
  FillsRelationsAspect,
  HashComponent,
  NumberKeyframe,
  PaintType,
  PaintTypeComponent,
  ProgressComponent,
  RgbaKeyframe,
  RgbaValueComponent,
  ScaleTypeComponent,
  SolidPaint,
  StrokesRelationsAspect,
  TimeComponent,
  UpdatesSystem,
  commitUndo,
  getSortedKeyframes,
  getValueRgba,
} from '@aninix-inc/model'
import { PropertyRowV2, buttons, icons } from '@aninix/app-design-system'
import { useEntities } from '@aninix/core'
import * as R from 'ramda'
import * as React from 'react'
import { GradientValue } from '../../values/gradient'
import { ImageValue } from '../../values/image'
import { RgbaValue } from '../../values/rgba'
import { KeyframesPropertyControl } from '../keyframes-property-control'
import * as styles from './index.scss'

const btnSize = {
  width: 32,
  height: 32,
}

/**
 * Check all possible cases for provided layers to check if paints are mixed or not.
 */
export const isPaintsMixed = (
  layers: Entity[],
  AspectConstructor: ConstructorWithTag<
    FillsRelationsAspect | StrokesRelationsAspect
  >
): boolean => {
  let firstLayer = layers[0]
  const firstLayerPaints = firstLayer
    .getAspectOrThrow(AspectConstructor)
    .getChildrenList()

  for (const layer of layers) {
    const paints = layer.getAspectOrThrow(AspectConstructor).getChildrenList()

    if (firstLayerPaints.length !== paints.length) {
      return true
    }

    for (let i = 0; i < paints.length; i += 1) {
      const firstLayerPaint = firstLayerPaints[i]
      const paint = paints[i]

      const firstLayerPaintType =
        firstLayerPaint.getComponentOrThrow(PaintTypeComponent).value
      const paintType = paint.getComponentOrThrow(PaintTypeComponent).value

      if (paintType !== firstLayerPaintType) {
        return true
      }

      switch (paintType) {
        case PaintType.Solid: {
          if (
            !R.equals(
              firstLayerPaint.getComponentOrThrow(RgbaValueComponent).value,
              paint.getComponentOrThrow(RgbaValueComponent).value
            )
          ) {
            return true
          }

          break
        }

        case PaintType.GradientLinear:
        case PaintType.GradientRadial: {
          const firstLayerColorStops = paint
            .getAspectOrThrow(ColorStopsRelationsAspect)
            .getChildrenList()
          const colorStops = paint
            .getAspectOrThrow(ColorStopsRelationsAspect)
            .getChildrenList()

          if (colorStops.length !== firstLayerColorStops.length) {
            return true
          }

          for (let ii = 0; ii < colorStops.length; ii += 1) {
            const firstLayerColorStop = firstLayerColorStops[ii]
            const colorStop = colorStops[ii]

            if (
              !R.equals(
                firstLayerColorStop.getComponentOrThrow(RgbaValueComponent)
                  .value,
                colorStop.getComponentOrThrow(RgbaValueComponent).value
              )
            ) {
              return true
            }

            if (
              !R.equals(
                firstLayerColorStop.getComponentOrThrow(ProgressComponent)
                  .value,
                colorStop.getComponentOrThrow(ProgressComponent).value
              )
            ) {
              return true
            }
          }

          break
        }

        case PaintType.Image: {
          if (
            !R.equals(
              paint.getComponentOrThrow(HashComponent).value,
              firstLayerPaint.getComponentOrThrow(HashComponent).value
            )
          ) {
            return true
          }

          if (
            !R.equals(
              paint.getComponentOrThrow(ScaleTypeComponent).value,
              firstLayerPaint.getComponentOrThrow(ScaleTypeComponent).value
            )
          ) {
            return true
          }

          break
        }

        default: {
          const never: never = paintType
          throw new Error(`Should be handled "${never}"`)
        }
      }
    }
  }

  return false
}

export const Paint: React.FC<{
  /**
   * Paints should be already filtered to specific type
   */
  paints: Entity[]
  time: number
}> = ({ paints, time }) => {
  useEntities(paints)
  const type = paints[0].getComponentOrThrow(PaintTypeComponent).value

  switch (type) {
    case PaintType.Solid: {
      return (
        <div className="flex w-full justify-between">
          <RgbaValue
            components={paints.map((f) =>
              f.getComponentOrThrow(RgbaValueComponent)
            )}
            time={time}
          />

          <KeyframesPropertyControl
            components={paints.map((f) =>
              f.getComponentOrThrow(RgbaValueComponent)
            )}
            time={time}
            KeyframeConstructor={RgbaKeyframe}
            valueGetter={getValueRgba}
          />
        </div>
      )
    }

    case PaintType.GradientLinear:
    case PaintType.GradientRadial: {
      const colorStops = paints.flatMap((p) =>
        p
          .getAspectOrThrow(ColorStopsRelationsAspect)
          .getChildrenList()
          .flatMap((colorStop) => [
            colorStop.getComponentOrThrow(RgbaValueComponent),
          ])
      )

      // @NOTE: don't render gradient if it doesn't have any color stops.
      // Related to ANI-1529.
      if (colorStops.length === 0) {
        return (
          <div className="flex w-full justify-between">
            <p className={styles.mixed}>
              Invalid data received from Figma. Please try to sync your project
              again. If the issue keeps happening,{' '}
              <a href="mailto:info@aninix.com" className="underline">
                contact us
              </a>
              .
            </p>
          </div>
        )
      }

      return (
        <div className="flex w-full justify-between">
          <GradientValue
            aspects={paints.map((p) =>
              p.getAspectOrThrow(ColorStopsRelationsAspect)
            )}
            time={time}
          />

          <KeyframesPropertyControl
            components={colorStops}
            time={time}
            KeyframeConstructor={RgbaKeyframe}
            valueGetter={getValueRgba}
            // Related to onToggle description.
            // This is a dirty hack required to fix the ANI-1170 task.
            onToggle={() => {
              const project = paints[0].getProjectOrThrow()

              const progresses = paints.flatMap((p) =>
                p
                  .getAspectOrThrow(ColorStopsRelationsAspect)
                  .getChildrenList()
                  .map((colorStop) =>
                    colorStop.getComponentOrThrow(ProgressComponent)
                  )
              )

              progresses.map((progress) => {
                const keyframes = getSortedKeyframes(progress)
                const keyframeAtTime = keyframes.find(
                  (k) => k.getComponentOrThrow(TimeComponent).value === time
                )
                if (keyframeAtTime) {
                  project.removeEntity(keyframeAtTime.id)
                  return
                }
                project.createEntity(NumberKeyframe, {
                  target: progress,
                  value: progress.value,
                  time,
                })
              })
            }}
          />
        </div>
      )
    }

    case PaintType.Image: {
      return (
        <ImageValue
          components={paints.map((p) => p.getComponentOrThrow(HashComponent))}
        />
      )
    }
  }
}

export interface IProps {
  layers: Entity[]
  time: number
}
export const Fills: React.FCC<IProps> = ({ layers, time }) => {
  useEntities(layers)
  const paints = layers.flatMap((l) =>
    l.getAspectOrThrow(FillsRelationsAspect).getChildrenList()
  )
  const project = layers[0].getProjectOrThrow()
  const updates = project.getSystemOrThrow(UpdatesSystem)
  const isMixed = isPaintsMixed(layers, FillsRelationsAspect)

  return (
    <PropertyRowV2
      name="Fill"
      headerButtons={
        <>
          {!isMixed && paints.length > 0 && (
            <buttons.Icon
              onClick={() => {
                updates.batch(() => {
                  layers.forEach((layer) => {
                    const internalPaints = layer
                      .getAspectOrThrow(FillsRelationsAspect)
                      .getChildrenList()

                    if (internalPaints.length === 0) {
                      return
                    }

                    layer
                      .getAspectOrThrow(FillsRelationsAspect)
                      .removeChild(R.last(internalPaints)!.id)
                  })
                })
                commitUndo(project)
              }}
              btnSize={btnSize}
            >
              <icons.Remove />
            </buttons.Icon>
          )}

          <buttons.Icon
            onClick={() => {
              updates.batch(() => {
                if (isMixed) {
                  layers.forEach((layer) => {
                    // @TODO: implement FillsComponent.clear() in the model
                    const fills = layer.getAspectOrThrow(FillsRelationsAspect)
                    fills.getChildrenList().forEach((child) => {
                      fills.removeChild(child.id)
                    })

                    // @TODO: implement FillsComponent.create in the model
                    fills.addChild(project.createEntity(SolidPaint))
                  })
                  commitUndo(project)
                  return
                }

                layers.forEach((layer) => {
                  layer
                    .getAspectOrThrow(FillsRelationsAspect)
                    .addChild(project.createEntity(SolidPaint))
                })
              })
              commitUndo(project)
            }}
            btnSize={btnSize}
          >
            <icons.Add />
          </buttons.Icon>
        </>
      }
      inputs={
        // @NOTE: similar logic of mixed content is used in the "strokes" component.
        // Maybe move it to separated <Paints /> component?
        paints.length === 0 ? undefined : isMixed ? (
          <p className={styles.mixed}>Click + to replace mixed content</p>
        ) : (
          <div className="flex w-full flex-col gap-[2px]">
            {paints.map((paint, idx) => {
              const selectedPaints = layers
                .map(
                  (l) =>
                    l.getAspectOrThrow(FillsRelationsAspect).getChildrenList()[
                      idx
                    ]
                )
                .filter(
                  (e) =>
                    e != null &&
                    e.getComponentOrThrow(PaintTypeComponent).value ===
                      paint.getComponentOrThrow(PaintTypeComponent).value
                )

              if (selectedPaints.length === 0) {
                return null
              }

              return (
                <Paint key={paint.id} paints={selectedPaints} time={time} />
              )
            })}
          </div>
        )
      }
      empty={paints.length === 0}
    />
  )
}
