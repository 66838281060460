import { RenderScaleType, RenderType } from '@aninix-inc/model'
import {
  Checkbox,
  Select,
  SelectProps,
  buttons,
  icons,
} from '@aninix/app-design-system'
import { Input, LinearProgress } from '@material-ui/core'
import classNames from 'classnames'
import * as React from 'react'
import * as styles from './view.scss'

export type ExportPresetView = {
  id: string
  type: RenderType
  scale: RenderScaleType
  suffix: string
}

export interface IProps {
  exportPresets: ExportPresetView[]
  activePresetIdx: number
  onUpdatePreset: (payload: {
    presetId: string
    preset: Partial<ExportPresetView>
  }) => void
  onExportStart: () => void
  onClose: () => void
  onCancel: () => void
  onAddPreset: () => void
  onRemovePreset: (payload: { presetId: string }) => void
  // NOTE: in range 0...1
  progress: number
  isRendering: boolean
  errorMessage?: React.ReactNode
  warningMessages?: React.ReactNode[]
  statusMessage?: React.ReactNode

  includeOnlySelectedRangeAllowed: boolean
  includeOnlySelectedRange: boolean
  toggleIncludeOnlySelectedRange: () => void
}
export const RenderView: React.FCC<IProps> = ({
  exportPresets,
  activePresetIdx,
  onUpdatePreset,
  onExportStart,
  onClose,
  onCancel,
  onAddPreset,
  onRemovePreset,
  progress,
  isRendering,
  errorMessage,
  warningMessages,
  statusMessage,

  includeOnlySelectedRangeAllowed,
  includeOnlySelectedRange,
  toggleIncludeOnlySelectedRange,
}) => {
  const handleCancel = () => {
    if (isRendering) {
      onCancel()
      return
    }

    onClose()
  }

  const handleChangeScale = (presetId: string, scale: RenderScaleType) => {
    onUpdatePreset({
      presetId,
      preset: {
        scale,
      },
    })
  }

  const handleChangeSuffix = (presetId: string, suffix: string) => {
    onUpdatePreset({
      presetId,
      preset: {
        suffix,
      },
    })
  }

  const handleChangeType = (presetId: string, type: RenderType) => {
    onUpdatePreset({
      presetId,
      preset: {
        type,
      },
    })
  }

  const handleAddPreset = () => {
    onAddPreset()
  }

  const handleRemovePreset = (presetId: string) => {
    onRemovePreset({ presetId })
  }

  const renderOptions: SelectProps['options'] = (() => {
    const base = [
      {
        id: RenderType.mp4,
        title: '.mp4',
      },
      {
        id: RenderType.webm,
        title: '.webm',
      },
      {
        id: RenderType.gif,
        title: '.gif',
      },
    ]

    const lottieOptions = [
      {
        id: RenderType.lottie,
        title: '.json (normal)',
      },
      {
        id: RenderType.dotLottie,
        title: '.lottie (optimized)',
      },
      {
        id: RenderType.tgs,
        title: '.tgs (telegram)',
      },
    ]

    const svgOptions = [
      {
        id: RenderType.svg,
        title: '.svg',
      },
    ]

    const pngSequenceOptions = [
      {
        id: RenderType.png,
        title: '.png (sequence)',
      },
    ]

    return [
      'Media',
      ...base,
      'divider',
      'Lottie',
      ...lottieOptions,
      'divider',
      'Other',
      ...svgOptions,
      ...pngSequenceOptions,
    ]
  })()

  const multiplePresets = exportPresets.length > 1

  return (
    <div>
      <div className={styles.top}>
        <div className={styles.header}>
          <p className={styles.title}>Export</p>

          <buttons.Icon
            onClick={handleAddPreset}
            className={classNames(styles['add-button'])}
            disabled={isRendering}
            tooltip="Add preset to queue"
          >
            <icons.Add />
          </buttons.Icon>
        </div>

        <div className={styles['preset-rows']}>
          {exportPresets.map((exportPreset, idx) => (
            <div
              key={exportPreset.id}
              className={classNames(styles['preset-row'], {
                [styles['preset-row--with-padding']]: multiplePresets,
                [styles['preset-row--active']]:
                  idx === activePresetIdx && multiplePresets,
              })}
            >
              <Select
                className="pl-[6px]"
                options={[
                  {
                    id: '0.5x',
                    title: '0.5×',
                  },
                  {
                    id: '0.75x',
                    title: '0.75×',
                  },
                  {
                    id: '1x',
                    title: '1×',
                  },
                  {
                    id: '1.5x',
                    title: '1.5×',
                  },
                  {
                    id: '2x',
                    title: '2×',
                  },
                  {
                    id: '3x',
                    title: '3×',
                  },
                  {
                    id: '4x',
                    title: '4×',
                  },
                ]}
                activeValueId={exportPreset.scale}
                onChange={(value) =>
                  handleChangeScale(exportPreset.id, value as RenderScaleType)
                }
                disabled={
                  isRendering ||
                  exportPreset.type === RenderType.lottie ||
                  exportPreset.type === RenderType.dotLottie ||
                  exportPreset.type === RenderType.tgs ||
                  exportPreset.type === RenderType.svg
                }
              />

              <Input
                value={exportPreset.suffix}
                onChange={(e) =>
                  handleChangeSuffix(exportPreset.id, e.target.value)
                }
                placeholder="Suffix"
                classes={{
                  root: styles.input,
                  disabled: styles['input--disabled'],
                }}
                disabled={isRendering}
              />

              <Select
                className="pl-[6px]"
                options={renderOptions}
                activeValueId={exportPreset.type}
                onChange={(value) =>
                  handleChangeType(exportPreset.id, value as RenderType)
                }
                disabled={isRendering}
              />

              {multiplePresets && (
                <buttons.Icon
                  onClick={() => handleRemovePreset(exportPreset.id)}
                  disabled={isRendering}
                >
                  <icons.Remove />
                </buttons.Icon>
              )}
            </div>
          ))}
        </div>
      </div>

      {isRendering ? (
        <LinearProgress
          variant="determinate"
          value={progress * 100}
          className={styles.progress}
        />
      ) : (
        <div className={styles['progress-placeholder']} />
      )}

      {statusMessage != null && (
        <div className={styles.status}>
          <div className={styles['status__icon']}>
            <div className={styles['status__icon-background']} />
            <div className={styles['status__icon-loading']} />
          </div>

          <p className={styles['status__description']}>{statusMessage}</p>
        </div>
      )}

      {warningMessages?.map((warningMessage, idx) => (
        <div className={styles.warning} key={idx}>
          <div className={styles['warning__icon']}>
            <icons.NotificationWarning
              style={{
                // @ts-ignore
                '--figma-color-text': '#FFC700',
                // @ts-ignore
                '--figma-color-text-brand': 'rgba(0, 0, 0, 0.8)',
              }}
            />
          </div>

          <div className={styles['warning__description']}>{warningMessage}</div>
        </div>
      ))}

      {errorMessage != null && (
        <div className={styles.error}>
          <div className={styles['error__icon']}>
            <icons.NotificationWarning
              style={{
                // @ts-ignore
                '--figma-color-text': '#f24822',
                // @ts-ignore
                '--figma-color-text-brand': 'rgba(255, 255, 255, 0.8)',
              }}
            />
          </div>

          <p className={styles['error__description']}>{errorMessage}</p>
        </div>
      )}

      <div className={styles.bottom}>
        {isRendering === false && includeOnlySelectedRangeAllowed && (
          <Checkbox
            onClick={toggleIncludeOnlySelectedRange}
            isChecked={includeOnlySelectedRange}
            label="Include only selected range"
          />
        )}

        <div className={styles.buttons}>
          <buttons.Regular
            variant="outlined"
            title="Cancel"
            onClick={handleCancel}
            fullWidth
          />

          <buttons.Regular
            variant="contained"
            title={errorMessage != null ? 'Try again' : 'Export'}
            onClick={onExportStart}
            fullWidth
            disabled={isRendering}
          />
        </div>
      </div>
    </div>
  )
}
