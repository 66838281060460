import { Point2D, VectorPath } from '@aninix-inc/model/legacy'
import * as math from 'mathjs'
import * as R from 'ramda'

export function mapWindingRule(
  windingRule: VectorPath['windingRule']
): 'nonzero' | 'evenodd' {
  if (windingRule === 'EVENODD') {
    return 'evenodd'
  }

  if (windingRule === 'NONZERO') {
    return 'nonzero'
  }

  return 'nonzero'
}

export function mapWindingRuleBack(
  windingRule: 'nonzero' | 'evenodd'
): VectorPath['windingRule'] {
  if (windingRule === 'evenodd') {
    return 'EVENODD'
  }

  if (windingRule === 'nonzero') {
    return 'NONZERO'
  }

  return 'NONZERO'
}

export type Matrix3x2 = [[number, number, number], [number, number, number]]
export type Matrix3x1 = [number, number, number]

/**
 * @description applying transform matrix as inverted affine transform
 * @param transform matrix
 * @param initial matrix
 * @returns coefficients for point to next operations
 */
export function getPointCoefficient(
  transform: Matrix3x2,
  initial: Matrix3x1
): Point2D {
  // @NOTE: required in odd cases when project receive invalid values for gradient transform
  const isMatrixInvalid = R.all(
    (row) => R.all((item) => item === 0, row),
    transform
  )
  if (isMatrixInvalid) {
    return { x: 0, y: 0 }
  }

  const transformMatrix = math.matrix([...transform, [0, 0, 1]])
  const invertedTransformMatrix = math.inv(transformMatrix)

  const [x, y] = math
    .multiply(invertedTransformMatrix, math.matrix(initial))
    .toArray() as Matrix3x1

  return { x, y }
}
