import {
  ColorStopsRelationsAspect,
  OpacityComponent,
  PaintTypeComponent,
  ProgressComponent,
  RgbaValueComponent,
  VisibleInViewportComponent,
  commitUndo,
  getAnimatableValue,
} from '@aninix-inc/model'
import { PaintControl } from '@aninix/app-design-system'
import { useEntities, useProject } from '@aninix/core'
import { observer } from 'mobx-react-lite'
import * as R from 'ramda'
import * as React from 'react'
import { useUpdates } from '../../../../../hooks/use-updates'

export interface IProps {
  aspects: ColorStopsRelationsAspect[]
  time?: number
}
export const GradientValue: React.FCC<IProps> = observer(
  ({ aspects, time }) => {
    const project = useProject()
    const updates = useUpdates()

    const rawColorStops = aspects.flatMap((a) =>
      R.sort(
        (left, right) =>
          left.getComponentOrThrow(ProgressComponent).value -
          right.getComponentOrThrow(ProgressComponent).value,
        a.getChildrenList()
      )
    )
    useEntities(rawColorStops)

    const colorStops = R.uniqBy(
      (colorStop) => `${colorStop.progress}-${JSON.stringify(colorStop.value)}`,
      rawColorStops.map((colorStop) => ({
        id: colorStop.id,
        value: getAnimatableValue(
          colorStop.getComponentOrThrow(RgbaValueComponent),
          time
        ),
        progress: getAnimatableValue(
          colorStop.getComponentOrThrow(ProgressComponent),
          time
        ),
      }))
    )
    const opacity =
      aspects[0].entity.getComponentOrThrow(OpacityComponent).value
    const visible = R.all(
      (e) => e.getComponentOrThrow(VisibleInViewportComponent).value,
      aspects.map((aspect) => aspect.entity)
    )
    const paintType =
      aspects[0].entity.getComponentOrThrow(PaintTypeComponent).value
    const endChange = () => commitUndo(project)

    // @TODO: provide support of mixed values
    return (
      <div className="flex flex-col">
        <PaintControl
          colorStops={colorStops}
          opacity={opacity}
          onGradientChange={(newColorStops, newOpacity, newType) => {
            updates.batch(() => {
              const colorStopsMap = Object.fromEntries(
                rawColorStops.map((colorStop) => [colorStop.id, colorStop])
              )

              for (const newColorStop of newColorStops ?? []) {
                if (newColorStop.id == null) {
                  continue
                }

                const targetColorStop = colorStopsMap[newColorStop.id!]!

                if (newColorStop.value != null) {
                  targetColorStop.updateComponent(
                    RgbaValueComponent,
                    newColorStop.value
                  )
                }

                if (newColorStop.progress != null) {
                  targetColorStop.updateComponent(
                    ProgressComponent,
                    newColorStop.progress
                  )
                }
              }

              if (newOpacity != null) {
                for (const aspect of aspects) {
                  aspect.entity.updateComponent(OpacityComponent, newOpacity)
                }
              }

              if (newType != null) {
                // @TODO: implement
              }
            })
          }}
          visible={visible}
          onVisibilityClick={() => {
            updates.batch(() => {
              aspects
                .map((aspect) => aspect.entity)
                .forEach((e) =>
                  e.updateComponent(VisibleInViewportComponent, (v) => !v)
                )
            })
          }}
          onEndChange={endChange}
          fill={{
            id: 'faux',
            type: paintType,
            visible,
            colorStops,
            opacity,
          }}
        />
      </div>
    )
  }
)
