import { Component, Entity } from '@aninix-inc/model'
import { safeString } from '@aninix/core'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import {
  getDuration,
  getKeySplines,
  getKeyTimes,
  getKeyframes,
  getRepeatCount,
  getSegments,
  getStartEnd,
} from './utils'

interface IProps {
  attributeName: string
  component: Component
  getKeyframeValue: (keyframe: Entity) => string
  applyTo?: string
  timeRange?: [number, number]
  startEvent?: string
  endEvent?: string
  additive?: React.SVGAttributes<SVGElement>['additive']
}
export const Property: React.FCC<IProps> = observer(
  ({
    attributeName,
    component,
    getKeyframeValue,
    applyTo,
    timeRange,
    startEvent,
    endEvent,
    additive = 'sum',
  }) => {
    const entity = component.entity
    const keyframes = getKeyframes(component)
    const [start, end] = getStartEnd(keyframes, timeRange)
    const duration = getDuration(keyframes, timeRange)
    const segments = getSegments(keyframes)
    const repeatCount = getRepeatCount()

    if (duration === 0) {
      return null
    }

    // return (
    //   <Portal containerId={applyTo ?? safeString(node.id)}>
    //     <animate
    //       attributeName={attributeName}
    //       values={keyframes
    //         .map((keyframe) => getKeyframeValue(keyframe.value, property.value))
    //         .join(';')}
    //       dur={`${duration}s`}
    //       repeatCount="indefinite"
    //       calcMode="spline"
    //       keyTimes={keyframes
    //         .map((keyframe) => round(keyframe.time, { fixed: 2 }))
    //         .map((time) => lerpRangeClamped(time, start, end, 0, 1))
    //         .map((time) => round(time, { fixed: 2 }))
    //         .join(';')}
    //       keySplines={segments.map(getSegmentCurve).join(';')}
    //       begin={startEvent}
    //       end={endEvent}
    //     />
    //   </Portal>
    // )

    return (
      <animate
        href={`#${applyTo ?? safeString(entity.id)}`}
        attributeName={attributeName}
        values={keyframes
          .map((keyframe) => getKeyframeValue(keyframe))
          .join(';')}
        dur={`${duration}s`}
        repeatCount={repeatCount}
        calcMode="spline"
        keyTimes={getKeyTimes({ keyframes, start, end })}
        keySplines={getKeySplines(segments)}
        additive={additive}
        fill="freeze"
        begin={startEvent}
        end={endEvent}
      />
    )
  }
)
