import * as React from 'react'

import { Icons } from '../../../components/icons'

import { Link } from 'react-router-dom'

export interface IProps {}
export const UpgradeToProBannerRouter: React.FC<IProps> = ({}) => {
  return (
    <div className="flex flex-col gap-2 rounded-xl bg-[#1BC47D]/10 p-3 text-[#0B1118]">
      <p className="flex flex-row items-center gap-2 text-base font-medium">
        <Icons.Pro color="#0B1118" />
        <span>Upgrade to PRO</span>
      </p>

      <p className="text-sm">
        Free accounts can only have 2 active projects on the Aninix platform.
        Remove the limits with PRO.
      </p>

      <div className="flex w-full flex-row gap-2">
        {/* @TODO: IMPORTANT extract to separated button component */}
        <Link
          to={'/upgrade'}
          target="_self"
          className="flex h-10 w-full flex-row items-center justify-center gap-2 rounded-lg bg-[#1BC47D] p-2 text-base text-white enabled:hover:bg-[#18B071] enabled:active:bg-[#169D64] disabled:cursor-no-drop disabled:opacity-70"
        >
          <span>Upgrade</span>
        </Link>
      </div>
    </div>
  )
}
