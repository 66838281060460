import { createProjectV2, syncProjectWithRemote } from '@aninix/api'
import { Loader } from '@aninix/app-design-system'
import { featureFlags } from '@aninix/core'
import { config } from '@aninix/core/config'
import { svgToAni } from '@aninix/core/use-cases'
import * as React from 'react'
import { useParams } from 'react-router-dom'
import { toast } from '../../../modules/toasts'

export const CreateProjectFromSvgButton: React.FCC = () => {
  const [isCreating, setIsCreating] = React.useState<boolean>(false)
  const inputFileRef = React.useRef<HTMLInputElement>(null)
  const { teamId } = useParams()

  const createProject = React.useCallback(
    async (data: string, name?: string) => {
      setIsCreating(true)

      const start = data.indexOf('<svg')
      const end = data.indexOf('</svg>') + 6
      const svg = data.slice(start, end)

      try {
        const project = svgToAni(svg)
        await createProjectV2({
          id: project.id,
          projectName: name ?? 'New project',
          teamId,
        })
        await syncProjectWithRemote(project)
        window.open(`${config.webAppUrl}/edit/${project.id}`, '_blank')
      } catch (e) {
        toast('Failed to create project', { variant: 'error' })
      } finally {
        setIsCreating(false)
        if (inputFileRef.current) inputFileRef.current.value = ''
      }
    },
    []
  )

  const readClipboard = React.useCallback(async () => {
    const data = await navigator.clipboard.readText()
    const isSvg = data?.includes('<svg')

    if (isSvg) {
      createProject(data)
    } else {
      inputFileRef.current?.dispatchEvent(new MouseEvent('click'))
    }
  }, [])

  const readFile = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.currentTarget.files?.[0]
      if (!file) return
      if (file.type !== 'image/svg+xml') {
        toast('Please select a .svg file', { variant: 'error' })
        return
      }

      const reader = new FileReader()

      reader.onload = (e) => {
        const svg = e.target?.result
        if (typeof svg !== 'string') {
          toast('Failed to read file', { variant: 'error' })
          return
        }

        createProject(svg, file.name.slice(0, -4))
      }
      reader.onerror = () => {
        toast('Failed to read file', { variant: 'error' })
      }
      reader.readAsText(file)
    },
    []
  )

  if (!featureFlags.importSvg) return null

  return (
    <button
      className="flex flex-row w-full justify-between items-center gap-2 bg-secondary px-3 py-2 font-body text-sm font-medium text-white transition-all duration-300 hover:shadow-lg relative"
      type="button"
      onClick={readClipboard}
      disabled={isCreating}
    >
      <p>From .svg</p>
      {isCreating && <Loader size={16} color="#fff" />}
      <input
        type="file"
        ref={inputFileRef}
        onChange={readFile}
        title={''}
        className="absolute top-0 left-0 w-full h-full pointer-events-none opacity-0"
        accept=".svg"
        disabled={isCreating}
      />
    </button>
  )
}
