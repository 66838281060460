import { Entity, NodeColorComponent, Project } from '@aninix-inc/model'
import { convertEntityToSnapshot as convertNodeToSnapshotV2 } from '@aninix-inc/renderer'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { nodeColors } from '../../../registries'
import { useImagesStore, usePlayback, useSession } from '../../../stores'
import { useEntity } from '../../../updates'
import { mapWindingRule } from './utils'


const EntityHighlight: React.FC<{ entity: Entity }> = observer(({ entity }) => {
  const images = useImagesStore()
  const playback = usePlayback()
  useEntity(entity)

  const snapshot = convertNodeToSnapshotV2({
    entity,
    time: playback.time,
    imagesStore: images,
  })

  if (snapshot.fillData.length === 0) {
    return null
  }

  return (
    <path
      vector-effect="non-scaling-stroke"
      transform={`matrix(${snapshot.absoluteTransformMatrix.values})`}
      d={snapshot.fillData.map((i) => i.data).join('')}
      fillRule={mapWindingRule(snapshot.fillData[0].windingRule)}
      fill="none"
      stroke={nodeColors[entity.getComponentOrThrow(NodeColorComponent).value]}
      strokeWidth={2}
    />
  )
})

export interface IProps {
  project: Project
}
export const SvgHighlighter: React.FCC<IProps> = observer(({ project }) => {
  const session = useSession()

  const buffer = session.buffer

  if (!buffer) {
    return null
  }

  return <EntityHighlight entity={project.getEntityOrThrow(buffer)} />
})

SvgHighlighter.displayName = 'SvgHighlighter'
