// @TODO: IMPORTANT refactor to optimize file size when exported
import {
  Entity,
  FillsRelationsAspect,
  PaintType,
  StrokeAlign,
  StrokeAlignComponent,
  StrokesRelationsAspect,
  getAnimatableValue,
  round,
} from '@aninix-inc/model'
import { getDataMap, paper } from '@aninix-inc/renderer'
import { safeString } from '@aninix/core'
import { observer } from 'mobx-react-lite'
import * as R from 'ramda'
import * as React from 'react'
import { NodeSnapshot } from '../../../modules/common/renderers/types'
import { ImagesStore } from '../../../stores'
// import { Portal } from './common/portal'
import {
  BottomLeftCornerRadiusComponent,
  BottomRightCornerRadiusComponent,
  CornerRadiusComponent,
  DashComponent,
  EndAngleComponent,
  GapComponent,
  InnerRadiusComponent,
  NodeTypeComponent,
  PaintTypeComponent,
  PointCountComponent,
  RgbaValueComponent,
  SizeComponent,
  StartAngleComponent,
  StrokeWeightComponent,
  TopLeftCornerRadiusComponent,
  TopRightCornerRadiusComponent,
  TrimEndComponent,
  TrimOffsetComponent,
  TrimStartComponent,
} from '@aninix-inc/model'
import { convertNodeToSnapshot } from '@aninix/core'
import {
  getDuration,
  getKeyTimes,
  getKeyframes,
  getRepeatCount,
  getSamplesForDuration,
  getStartEnd,
} from './common/utils'

type VectorPathKeyframe = VectorPath & { progress: number }
type VectorPathKeyframes = Array<VectorPathKeyframe>

/**
 * It takes array of strings and remove inbetween duplicates.
 * @example
 * Imagine we have path: `0; 0; 0; 1; 2; 3; 3; 3`
 * Then we should get: `0; 1; 2; 3;`
 */
function withoutDuplicates(
  keyframes: VectorPathKeyframes
): VectorPathKeyframes {
  let array: VectorPathKeyframe[] = [keyframes[0]]
  for (let i = 1; i < keyframes.length - 1; i += 1) {
    const prevKeyframe = keyframes[i - 1]
    const keyframe = keyframes[i]
    const nextKeyframe = keyframes[i + 1]

    if (
      prevKeyframe.data === keyframe.data &&
      keyframe.data === nextKeyframe.data
    ) {
      continue
    }

    array.push(keyframe)
  }
  array.push(keyframes[keyframes.length - 1])

  return array
}

/**
 * Remove zero deltas from path.
 * @example M10,20 v0 h0 l5,10 v0 h0 -> M10,20 l5,10
 */
function withoutZeroDeltas(path: string): string {
  return path.replaceAll('v0', '').replaceAll('h0', '').replaceAll('l0,0', '')
}

interface IStrokeShapeAnimation {
  entity: Entity
  snapshot: NodeSnapshot
  timeRange?: [number, number]
  startEvent?: string
  endEvent?: string
}
const StrokeShapeAnimation: React.FCC<IStrokeShapeAnimation> = observer(
  ({ entity, snapshot, timeRange, startEvent, endEvent }) => {
    const componentsToWork = [
      entity.getComponent(StartAngleComponent),
      entity.getComponent(EndAngleComponent),
      entity.getComponent(InnerRadiusComponent),
      entity.getComponent(SizeComponent),
      entity.getComponent(StrokeWeightComponent),
      entity.getComponent(PointCountComponent),
      entity.getComponent(CornerRadiusComponent),
      entity.getComponent(TopLeftCornerRadiusComponent),
      entity.getComponent(TopRightCornerRadiusComponent),
      entity.getComponent(BottomRightCornerRadiusComponent),
      entity.getComponent(BottomLeftCornerRadiusComponent),
    ].filter((property) => property != null)

    const keyframes = componentsToWork.flatMap((component) =>
      getKeyframes(component!)
    )
    const duration = getDuration(keyframes, timeRange)

    if (keyframes.length === 0) {
      return null
    }

    const samples = getSamplesForDuration(duration)

    const preparedRawKeyframes: VectorPathKeyframes = R.range(
      0,
      Math.round(samples) + 1
    ).flatMap((sample) => {
      const progress = sample / samples
      const nodeType = entity.getComponentOrThrow(NodeTypeComponent).value
      return {
        ...getDataMap[nodeType]({
          entity,
          time: progress * duration,
        })[0],
        progress: round(R.clamp(0, 1, progress), { fixed: 2 }),
      }
    })
    const preparedKeyframes = withoutDuplicates(preparedRawKeyframes)

    // return (
    //   <>
    //     {snapshot.strokes.map((stroke) => (
    //       <Portal
    //         key={stroke.id}
    //         containerId={`${safeString(node.id)}_stroke_path`}
    //       >
    //         <animate
    //           key={stroke.id}
    //           attributeName="d"
    //           values={preparedKeyframes.flatMap((key) => key.data).join(';')}
    //           dur={`${duration}s`}
    //           repeatCount="indefinite"
    //           keyTimes={preparedKeyframes
    //             .map((keyframe) => `${keyframe.progress}`)
    //             .join(';')}
    //           begin={startEvent}
    //           end={endEvent}
    //         />
    //       </Portal>
    //     ))}
    //   </>
    // )

    return (
      <>
        {snapshot.strokes.map((stroke) => (
          <animate
            key={stroke.id}
            href={`#${safeString(entity.id)}_stroke_path`}
            attributeName="d"
            values={preparedKeyframes
              .map((key) => key.data)
              .map(withoutZeroDeltas)
              .join(';')}
            dur={`${duration}s`}
            repeatCount="indefinite"
            keyTimes={preparedKeyframes.map((key) => key.progress).join(';')}
            fill="freeze"
            begin={startEvent}
            end={endEvent}
          />
        ))}
      </>
    )
  }
)

StrokeShapeAnimation.displayName = 'StrokeShapeAnimation'

interface IStrokeAnimation {
  entity: Entity
  snapshot: NodeSnapshot
  timeRange?: [number, number]
  startEvent?: string
  endEvent?: string
}
const StrokeAnimation: React.FCC<IStrokeAnimation> = observer(
  ({ entity, snapshot, timeRange, startEvent, endEvent }) => {
    const keyframes = getKeyframes(
      entity.getComponentOrThrow(StrokeWeightComponent)
    )
    const duration = getDuration(keyframes, timeRange)

    if (keyframes.length === 0) {
      return null
    }

    const samples = getSamplesForDuration(duration)

    const preparedKeyframes: Array<{ value: number; progress: number }> =
      R.range(0, Math.round(samples) + 1).flatMap((sample) => {
        const progress = sample / samples
        return {
          value:
            entity.getComponentOrThrow(StrokeAlignComponent).value ===
            StrokeAlign.Center
              ? getAnimatableValue(
                  entity.getComponentOrThrow(StrokeWeightComponent),
                  progress * duration
                )
              : getAnimatableValue(
                  entity.getComponentOrThrow(StrokeWeightComponent),
                  progress * duration
                ) / 2,
          progress: round(R.clamp(0, 1, progress), { fixed: 2 }),
        }
      })

    // return (
    //   <>
    //     {snapshot.strokes.map((stroke) => (
    //       <Portal
    //         key={stroke.id}
    //         containerId={`${safeString(node.id)}_stroke_path`}
    //       >
    //         <animate
    //           key={stroke.id}
    //           attributeName="d"
    //           values={preparedKeyframes.flatMap((key) => key.data).join(';')}
    //           dur={`${duration}s`}
    //           repeatCount="indefinite"
    //           keyTimes={preparedKeyframes
    //             .map((keyframe) => `${keyframe.progress}`)
    //             .join(';')}
    //           begin={startEvent}
    //           end={endEvent}
    //         />
    //       </Portal>
    //     ))}
    //   </>
    // )

    return (
      <>
        {snapshot.strokes.map((stroke) => (
          <animate
            key={stroke.id}
            href={`#${safeString(entity.id)}_stroke_path`}
            attributeName="stroke-width"
            values={preparedKeyframes.map((key) => key.value).join(';')}
            dur={`${duration}s`}
            repeatCount="indefinite"
            keyTimes={preparedKeyframes.map((key) => key.progress).join(';')}
            fill="freeze"
            begin={startEvent}
            end={endEvent}
          />
        ))}
      </>
    )
  }
)

StrokeAnimation.displayName = 'StrokeAnimation'

interface ITrimPathAnimation {
  entity: Entity
  snapshot: NodeSnapshot
  timeRange?: [number, number]
  startEvent?: string
  endEvent?: string
}
const TrimPathAnimation: React.FCC<ITrimPathAnimation> = observer(
  ({ entity, snapshot, timeRange, startEvent, endEvent }) => {
    const componentsToWork = [
      entity.getComponent(DashComponent),
      entity.getComponent(GapComponent),
      entity.getComponent(TrimStartComponent),
      entity.getComponent(TrimEndComponent),
      entity.getComponent(TrimOffsetComponent),
    ].filter((property) => property != null)

    const keyframes = componentsToWork.flatMap((component) =>
      getKeyframes(component!)
    )
    const [start] = getStartEnd(keyframes, timeRange)
    const duration = getDuration(keyframes, timeRange)

    if (keyframes.length === 0) {
      return null
    }

    // @NOTE: make smaller number of samples
    const samples = getSamplesForDuration(duration * 0.1)
    const progresses: number[] = R.range(0, Math.round(samples) + 1).flatMap(
      (sample) => {
        const progress = sample / samples
        return round(R.clamp(0, 1, progress), { fixed: 2 })
      }
    )
    const repeatCount = getRepeatCount()

    const data = getDataMap[
      entity.getComponentOrThrow(NodeTypeComponent).value
    ]({
      entity,
      time: 0,
    })[0].data
    const path = new paper.CompoundPath(data)

    return (
      <>
        {snapshot.strokes.map((stroke) => (
          <React.Fragment key={stroke.id}>
            <animate
              href={`#${safeString(entity.id)}_stroke_path`}
              attributeName="stroke-dasharray"
              values={progresses
                .map((progress) => {
                  const time = progress * duration + start
                  // @TODO: add support of dash and gap
                  // const dash = node.dash.getValue(time)
                  // const gap = node.gap.getValue(time)
                  const trimStart = entity.hasComponent(TrimStartComponent)
                    ? getAnimatableValue(
                        entity.getComponentOrThrow(TrimStartComponent),
                        time
                      )
                    : 0
                  const trimEnd = entity.hasComponent(TrimEndComponent)
                    ? getAnimatableValue(
                        entity.getComponentOrThrow(TrimEndComponent),
                        time
                      )
                    : 1
                  return `${path.length * (trimEnd - trimStart)} ${
                    path.length * (1 - (trimEnd - trimStart - 0.01))
                  }`
                })
                .join(';')}
              dur={`${duration}s`}
              repeatCount={repeatCount}
              keyTimes={progresses.join(';')}
              fill="freeze"
              begin={startEvent}
              end={endEvent}
            />
            <animate
              href={`#${safeString(entity.id)}_stroke_path`}
              attributeName="stroke-dashoffset"
              values={progresses
                .map((progress) => {
                  const time = progress * duration + start
                  const trimStart = entity.hasComponent(TrimStartComponent)
                    ? getAnimatableValue(
                        entity.getComponentOrThrow(TrimStartComponent),
                        time
                      )
                    : 0
                  const trimOffset = entity.hasComponent(TrimOffsetComponent)
                    ? getAnimatableValue(
                        entity.getComponentOrThrow(TrimOffsetComponent),
                        time
                      )
                    : 0
                  return -1 * (trimStart + trimOffset) * path.length
                })
                .join(';')}
              dur={`${duration}s`}
              repeatCount={repeatCount}
              keyTimes={progresses.join(';')}
              fill="freeze"
              begin={startEvent}
              end={endEvent}
            />
          </React.Fragment>
        ))}
      </>
    )
  }
)

TrimPathAnimation.displayName = 'TrimPathAnimation'

interface IFillShapeAnimation {
  entity: Entity
  snapshot: NodeSnapshot
  timeRange?: [number, number]
  startEvent?: string
  endEvent?: string
}
const FillShapeAnimation: React.FCC<IFillShapeAnimation> = observer(
  ({ entity, snapshot, timeRange, startEvent, endEvent }) => {
    const componentsToWork = [
      entity.getComponent(StartAngleComponent),
      entity.getComponent(EndAngleComponent),
      entity.getComponent(InnerRadiusComponent),
      entity.getComponent(SizeComponent),
      entity.getComponent(StrokeWeightComponent),
      entity.getComponent(PointCountComponent),
      entity.getComponent(CornerRadiusComponent),
      entity.getComponent(TopLeftCornerRadiusComponent),
      entity.getComponent(TopRightCornerRadiusComponent),
      entity.getComponent(BottomRightCornerRadiusComponent),
      entity.getComponent(BottomLeftCornerRadiusComponent),
    ].filter((property) => property != null)

    const keyframes = componentsToWork.flatMap((component) =>
      getKeyframes(component!)
    )
    const duration = getDuration(keyframes, timeRange)

    if (keyframes.length === 0) {
      return null
    }

    const samples = getSamplesForDuration(duration)

    const preparedRawKeyframes: VectorPathKeyframes = R.range(
      0,
      Math.round(samples) + 1
    ).flatMap((sample) => {
      const progress = sample / samples
      const nodeType = entity.getComponentOrThrow(NodeTypeComponent).value
      return {
        ...getDataMap[nodeType]({
          entity,
          time: progress * duration,
        })[0],
        progress: round(R.clamp(0, 1, progress), { fixed: 2 }),
      }
    })
    const preparedKeyframes = withoutDuplicates(preparedRawKeyframes)

    // return (
    //   <>
    //     {snapshot.fills.map((fill) => (
    //       <Portal
    //         key={fill.id}
    //         containerId={`#${safeString(node.id)}_fill_path`}
    //       >
    //         <animate
    //           attributeName="d"
    //           values={preparedKeyframes.flatMap((key) => key.data).join(';')}
    //           dur={`${duration}s`}
    //           repeatCount="indefinite"
    //           keyTimes={preparedKeyframes
    //             .map((keyframe) => `${keyframe.progress}`)
    //             .join(';')}
    //           begin={startEvent}
    //           end={endEvent}
    //         />
    //       </Portal>
    //     ))}
    //   </>
    // )

    return (
      <>
        {snapshot.fills.map((fill) => (
          <animate
            key={fill.id}
            href={`#${safeString(entity.id)}_fill_path`}
            attributeName="d"
            values={preparedKeyframes
              .map((key) => key.data)
              .map(withoutZeroDeltas)
              .join(';')}
            dur={`${duration}s`}
            repeatCount="indefinite"
            keyTimes={preparedKeyframes.map((key) => key.progress).join(';')}
            fill="freeze"
            begin={startEvent}
            end={endEvent}
          />
        ))}
      </>
    )
  }
)

FillShapeAnimation.displayName = 'FillShapeAnimation'

interface IPaintProps {
  aspect: FillsRelationsAspect | StrokesRelationsAspect
  type: 'stroke' | 'fill'
  timeRange?: [number, number]
  startEvent?: string
  endEvent?: string
}
const PaintAnimation: React.FCC<IPaintProps> = observer(
  ({ aspect, type, timeRange, startEvent, endEvent }) => {
    const entity = aspect.entity

    // return (
    //   <Portal containerId={`${safeString(node.id)}_${type}_path`}>
    //     {/* @TODO: add support of gradient animation */}
    //     {group.children.map((paint: PaintPropertyGroupSet) => {
    //       if (paintType === PaintType.Solid) {
    //         const keyframes = getKeyframes(paint.color)
    //         const preparedKeyframes = keyframes.map(
    //           (keyframe: GenericKeyframe) => {
    //             const value = keyframe.value

    //             return {
    //               color: `rgb(${value.r}, ${value.g}, ${value.b}`,
    //               opacity: value.a,
    //               time: keyframe.time,
    //             }
    //           }
    //         )
    //         const duration = getDuration(keyframes)
    //         const [start, end] = getStartEnd(keyframes, timeRange)

    //         if (duration === 0) {
    //           return null
    //         }

    //         return (
    //           <React.Fragment key={paint.id}>
    //             <animate
    //               attributeName={type}
    //               values={preparedKeyframes
    //                 .flatMap((key) => key.color)
    //                 .join(';')}
    //               dur={`${duration}s`}
    //               repeatCount="indefinite"
    //               keyTimes={preparedKeyframes
    //                 .map((keyframe) => round(keyframe.time, { fixed: 2 }))
    //                 .map((time) => lerpRangeClamped(time, start, end, 0, 1))
    //                 .map((time) => round(time, { fixed: 2 }))
    //                 .join(';')}
    //               begin={startEvent}
    //               end={endEvent}
    //             />

    //             <animate
    //               attributeName={`${type}-opacity`}
    //               values={preparedKeyframes
    //                 .flatMap((key) => key.opacity)
    //                 .join(';')}
    //               dur={`${duration}s`}
    //               repeatCount="indefinite"
    //               keyTimes={preparedKeyframes
    //                 .map((keyframe) => round(keyframe.time, { fixed: 2 }))
    //                 .map((time) => lerpRangeClamped(time, start, end, 0, 1))
    //                 .map((time) => round(time, { fixed: 2 }))
    //                 .join(';')}
    //               begin={startEvent}
    //               end={endEvent}
    //             />
    //           </React.Fragment>
    //         )
    //       }

    //       return null
    //     })}
    //   </Portal>
    // )

    return (
      <>
        {/* @TODO: add support of gradient animation */}
        {aspect.getChildrenList().map((paint) => {
          const paintType = paint.getComponentOrThrow(PaintTypeComponent).value

          if (paintType === PaintType.Solid) {
            const keyframes = getKeyframes(
              paint.getComponentOrThrow(RgbaValueComponent)
            )
            const preparedKeyframes = keyframes.map((keyframe) => {
              const value =
                keyframe.getComponentOrThrow(RgbaValueComponent).value

              return {
                color: `rgb(${value.r}, ${value.g}, ${value.b}`,
                opacity: value.a,
              }
            })
            const [start, end] = getStartEnd(keyframes, timeRange)
            const duration = getDuration(keyframes, timeRange)
            const repeatCount = getRepeatCount()

            if (duration === 0) {
              return null
            }

            return (
              <React.Fragment key={paint.id}>
                <animate
                  href={`#${safeString(entity.id)}_${type}_path`}
                  attributeName={type}
                  values={preparedKeyframes
                    .flatMap((key) => key.color)
                    .join(';')}
                  dur={`${duration}s`}
                  repeatCount={repeatCount}
                  keyTimes={getKeyTimes({ keyframes, start, end })}
                  fill="freeze"
                  begin={startEvent}
                  end={endEvent}
                />

                <animate
                  href={`#${safeString(entity.id)}_${type}_path`}
                  attributeName={`${type}-opacity`}
                  values={preparedKeyframes
                    .flatMap((key) => key.opacity)
                    .join(';')}
                  dur={`${duration}s`}
                  repeatCount={repeatCount}
                  keyTimes={getKeyTimes({ keyframes, start, end })}
                  fill="freeze"
                  begin={startEvent}
                  end={endEvent}
                />
              </React.Fragment>
            )
          }

          return <React.Fragment key={paint.id} />
        })}
      </>
    )
  }
)

PaintAnimation.displayName = 'PaintAnimation'

export interface IProps {
  entity: Entity
  images: ImagesStore
  timeRange?: [number, number]
  startEvent?: string
  endEvent?: string
}
export const GeometryAnimation: React.FCC<IProps> = observer(
  ({ entity, images, timeRange, startEvent, endEvent }) => {
    const snapshot = convertNodeToSnapshot({
      entity,
      time: 0,
      imagesStore: images,
    })

    return (
      <>
        {entity.hasAspect(FillsRelationsAspect) && (
          <>
            <PaintAnimation
              aspect={entity.getAspectOrThrow(FillsRelationsAspect)}
              type="fill"
              timeRange={timeRange}
              startEvent={startEvent}
              endEvent={endEvent}
            />
            <FillShapeAnimation
              entity={entity}
              snapshot={snapshot}
              timeRange={timeRange}
              startEvent={startEvent}
              endEvent={endEvent}
            />
          </>
        )}

        {entity.hasAspect(StrokesRelationsAspect) && (
          <>
            <PaintAnimation
              aspect={entity.getAspectOrThrow(StrokesRelationsAspect)}
              type="stroke"
              timeRange={timeRange}
              startEvent={startEvent}
              endEvent={endEvent}
            />
            <StrokeShapeAnimation
              entity={entity}
              snapshot={snapshot}
              timeRange={timeRange}
              startEvent={startEvent}
              endEvent={endEvent}
            />
          </>
        )}

        {entity.hasAspect(StrokesRelationsAspect) && (
          <>
            <StrokeAnimation
              entity={entity}
              snapshot={snapshot}
              timeRange={timeRange}
              startEvent={startEvent}
              endEvent={endEvent}
            />
            <TrimPathAnimation
              entity={entity}
              snapshot={snapshot}
              timeRange={timeRange}
              startEvent={startEvent}
              endEvent={endEvent}
            />
          </>
        )}

        {/* StrokeDashOffsetAnimation */}
        {/* {node.dashOffset && (
          <Property
            entity={entity}
            property={node.dashOffset}
            attributeName="stroke-dashoffset"
            getKeyframeValue={(value) => value}
            applyTo={`${safeString(node.id)}_stroke_path`}
            timeRange={timeRange}
            startEvent={startEvent}
            endEvent={endEvent}
          />
        )} */}
      </>
    )
  }
)

GeometryAnimation.displayName = 'GeometryAnimation'
