import {
  Patch,
  Project,
  UndoRedoSystem,
  UpdatesSystem,
} from '@aninix-inc/model'
import { AxiosResponse } from 'axios'
import { config } from '../../../apps/web-app/src/config'
import { httpClient } from './http-client'
import { paths } from './open-api/schema'
import { patchRemoteProject } from './patch-remote-project'

/**
 * It request remove patch diff and remote version.
 * Then patch local version.
 * And send required operations to the server.
 *
 * @mutate project
 */
export async function syncProjectWithRemote(project: Project): Promise<void> {
  // @NOTE: get remote patch
  const { data: remotePatch } = await httpClient.post<
    paths['/v2/projects/{projectId}/patches/diff']['post']['responses']['200']['content']['application/json'],
    AxiosResponse<
      paths['/v2/projects/{projectId}/patches/diff']['post']['responses']['200']['content']['application/json']
    >,
    paths['/v2/projects/{projectId}/patches/diff']['post']['requestBody']['content']['application/json']
  >(
    `${config.apiUrl}/v2/projects/${project.id}/patches/diff`,
    // @TODO: provide proper type here
    project.getVersion() as any
  )

  // @NOTE: and remote version
  const { data: remoteVersion } = await httpClient.get<
    paths['/v2/projects/{projectId}/versions/latest']['get']['responses']['200']['content']['application/json']
  >(`${config.apiUrl}/v2/projects/${project.id}/versions/latest`)

  const updates = project.getSystem(UpdatesSystem)
  const undoRedo = project.getSystem(UndoRedoSystem)
  if (updates != null) {
    updates.batch(() => {
      project.applyPatch(remotePatch as Patch)
    })
  } else {
    project.applyPatch(remotePatch as Patch)
  }
  undoRedo?.commitUndo()

  // @TODO: provide proper type here
  await patchRemoteProject(project, remoteVersion as any)
}
