import {
  Entity,
  UndoRedoSystem,
  UpdatesSystem,
  getEndTime,
  getStartTime,
  mixed,
  setEndTime,
  setStartTime,
} from '@aninix-inc/model'
import {
  InputWithButton,
  PropertyRowV2,
  icons,
} from '@aninix/app-design-system'
import { useEntities, useProject } from '@aninix/core'
import * as R from 'ramda'
import * as React from 'react'

const iconSize = {
  x: 16,
  y: 16,
}

export interface IProps {
  layers: Entity[]
  time: number
}
export const TrimLayer: React.FCC<IProps> = ({ layers, time }) => {
  useEntities(layers)

  const project = useProject()
  const undoRedo = project.getSystemOrThrow(UndoRedoSystem)
  const updates = project.getSystemOrThrow(UpdatesSystem)

  const valuesStart = layers.map((l) => getStartTime(l))
  const valuesEnd = layers.map((l) => getEndTime(l))

  const equalsStart = R.all((value) => value === valuesStart[0], valuesStart)
  const equalsEnd = R.all((value) => value === valuesEnd[0], valuesEnd)

  return (
    <PropertyRowV2
      name="Trim Layer"
      inputs={
        <>
          <InputWithButton
            id="trim-start"
            value={equalsStart ? valuesStart[0] : mixed}
            onClick={() => {
              updates.batch(() => {
                layers.forEach((layer) => {
                  setStartTime(layer, time)
                })
              })
              undoRedo.commitUndo()
            }}
            onChange={(value) => {
              updates.batch(() => {
                layers.forEach((layer) => {
                  setStartTime(layer, value)
                })
              })
              undoRedo.commitUndo()
            }}
            icon={
              <icons.propertiesPanel.TrimLayer type="left" size={iconSize} />
            }
            width={96}
            nullable
          />

          <InputWithButton
            id="trim-end"
            value={equalsEnd ? valuesEnd[0] : mixed}
            onClick={() => {
              updates.batch(() => {
                layers.forEach((layer) => {
                  setEndTime(layer, time)
                })
              })
              undoRedo.commitUndo()
            }}
            onChange={(value) => {
              updates.batch(() => {
                layers.forEach((layer) => {
                  setEndTime(layer, value)
                })
              })
              undoRedo.commitUndo()
            }}
            icon={
              <icons.propertiesPanel.TrimLayer type="right" size={iconSize} />
            }
            width={96}
            nullable
          />
        </>
      }
    />
  )
}
