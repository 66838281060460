import { useGetSpaceBadge } from '@aninix/api'
import * as React from 'react'
import { Navigate, Outlet, useOutletContext, useParams } from 'react-router-dom'

import { NavigationBar } from '../../components'
import { MaintenanceModeBanner } from '../../components/maintenance-mode-banner'
import { CreateNewFolderButton } from '../../components/navigation-bar/create-new-folder-button'

import { InviteMembersButton } from '../../components/navigation-bar/invite-members-button'
import { config } from '../../config'
import { useSessionStore } from '../../stores'
import { CurrentTeamStore, useCurrentSpaceStore } from '../../use-cases'
import { TeamSideMenu } from '../side-menu/team-side-menu'
import { CreateProjectButton } from '../../components/navigation-bar/create-project'

type Control = 'None' | 'InviteMembers' | 'CreateFolder' | 'CreateProject'

interface PageHeaderSetters {
  setTitle: React.Dispatch<React.SetStateAction<string>>
  setControls: React.Dispatch<React.SetStateAction<Control | Control[]>>
}

interface RefetchCurrentFolder {
  refetchCurrentFolder: () => void
  setRefetchCurrentFolder: React.Dispatch<React.SetStateAction<() => void>>
}

const noop = () => {}

export interface IProps {}
export const Teams: React.FCC<IProps> = () => {
  const { teamId } = useParams()

  const sessionStore = useSessionStore()

  React.useEffect(() => {
    if (teamId !== undefined) sessionStore.updateTeamId(teamId)
    console.log(teamId, sessionStore.teamId)
  }, [teamId])

  const [title, setTitle] = React.useState<string>('')

  const [controls, setControls] = React.useState<Control | Control[]>('None')
  const [refetchCurrentFolder, setRefetchCurrentFolder] =
    React.useState<() => void>(noop)

  const Controls: Record<Control, React.FCC<any>> = {
    None: () => null,
    InviteMembers: InviteMembersButton,
    CreateFolder: () => (
      <CreateNewFolderButton refetchCurrentFolder={refetchCurrentFolder} />
    ),
    CreateProject: CreateProjectButton,
  } as const

  const { currentUser } = useCurrentSpaceStore()

  const scrollRef = React.useRef<HTMLDivElement>(null)

  if (currentUser === undefined) return null

  return (
    <>
      {config.maintenance && <MaintenanceModeBanner />}

      <CurrentTeamStore>
        <div className="flex h-screen min-w-[900px] flex-row overflow-hidden">
          {/* <SideMenu page="teams" selectedPage={page} /> */}
          <div className="border-r-solid w-[300px] flex-shrink-0 overflow-y-auto border-r-[1px] border-r-gray-200 p-6">
            <TeamSideMenu />
          </div>
          <div
            className="flex h-screen w-full flex-col gap-6 overflow-auto px-10 py-6"
            ref={scrollRef}
          >
            <NavigationBar
              title={title}
              Controls={
                Array.isArray(controls)
                  ? controls.map((c) => Controls[c])
                  : Controls[controls]
              }
            />
            <div className="h-full">
              <Outlet
                context={{
                  setTitle,
                  setControls,
                  refetchCurrentFolder,
                  setRefetchCurrentFolder,
                  scrollRef,
                }}
              />
            </div>
          </div>
        </div>
      </CurrentTeamStore>
    </>
  )
}

export const TeamsWithoutUi: React.FCC<IProps> = ({ children }) => {
  const { teamId } = useParams()

  const sessionStore = useSessionStore()

  React.useEffect(() => {
    if (teamId !== undefined) sessionStore.updateTeamId(teamId)
    console.log(teamId, sessionStore.teamId)
  }, [teamId])

  const { currentUser } = useCurrentSpaceStore()

  if (currentUser === undefined) return null

  return <CurrentTeamStore>{children}</CurrentTeamStore>
}

export const usePageOutletContext = () =>
  useOutletContext<
    PageHeaderSetters &
      RefetchCurrentFolder & { scrollRef: React.RefObject<HTMLDivElement> }
  >()

Teams.displayName = 'Teams'

export const RedirectToTeam = () => {
  const sessionStore = useSessionStore()

  const tokensArray = Object.values(sessionStore.tokens ?? '')
  const users = useGetSpaceBadge({
    tokens: tokensArray,
  }).data?.infoFromTokens.users

  const currentUser = users?.filter((u) => u.id === sessionStore.userId)[0]

  if (currentUser === undefined) return null

  if (currentUser.teams.length === 0) return <Navigate to={'../my'} />

  const firstTeam = currentUser.teams[0].id

  return <Navigate to={firstTeam} />
}
